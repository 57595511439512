import React from "react";
import { List } from "react-content-loader";

function ConfirmPaymentPlaceholder() {
  return (
    <div className="wrapper py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mx-auto">
            <div className="card">
              <div className="px-4">
                <List backgroundColor={"#ccc"} className="p-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPaymentPlaceholder;
