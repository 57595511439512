import PropTypes from "prop-types";
import React from "react";

export default function PaymentAchDetail({
  accountNumber,
  handleFocus,
  message,
  processing,
  routingNumber,
  setAccountNumber,
  setRoutingNumber,
  submitAchPayment,
  totalAmount,
  containerClassName,
}) {
  return (
    <div className={containerClassName}>
      <h3 className="text-center font-weight-bold mb-5">Enter Bank Account Details</h3>
      <h5 className="text-center font-weight-bold mb-4">Total Amount: ${parseFloat(totalAmount).toFixed(2)}</h5>
      <div className="form-group">
        <input
          type="text"
          className="form-control form-control-lg"
          placeholder="Routing Number"
          name="routing_number"
          value={routingNumber}
          onChange={(e) => setRoutingNumber(e.target.value)}
          onFocus={(e) => handleFocus("routing_number", e)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          className="form-control form-control-lg"
          placeholder="Account Number"
          name="account_number"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          onFocus={(e) => handleFocus("account_number", e)}
        />
      </div>
      {message && message !== "" && (
        <div className="form-group">
          <p className="text-danger">{message}</p>
        </div>
      )}
      <div className="form-group text-center">
        <button className="btn btn-pink btn-lg" onClick={submitAchPayment} disabled={processing}>
          Make Payment
        </button>
      </div>
    </div>
  );
}

PaymentAchDetail.propTypes = {
  accountNumber: PropTypes.any,
  handleFocus: PropTypes.func,
  message: PropTypes.string,
  processing: PropTypes.any,
  routingNumber: PropTypes.any,
  setAccountNumber: PropTypes.func,
  setRoutingNumber: PropTypes.func,
  submitAchPayment: PropTypes.any,
  totalAmount: PropTypes.any,
  containerClassName: PropTypes.string,
};
