import React from "react";
import { BrowserRouter } from "react-router-dom";

import SwitchPages from "./Components/Routes/SwitchPages";
import AccessibleButton from "./Components/shared/AccessibleButton";
import AccessibleText from "./Components/shared/AccessibleText";
import Banner from "./Components/shared/Banner";
import KeyboardButton from "./Components/shared/KeyboardButton";
import ScreenKeyboard from "./Components/Utils/ScreenKeyboard";

class App extends React.Component {
  constructor(props) {
    super(props);
    const accessible = sessionStorage.getItem("accessible") === "on";

    this.state = {
      isLogin: false,
      authLoading: "",
      accessible: accessible,
      keyboard: false,
    };
  }

  toggleAccessible = () => {
    sessionStorage.setItem("accessible", this.state.accessible ? "off" : "on");
    this.setState({ accessible: !this.state.accessible });

    setTimeout(() => {
      if (this.state.accessible) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    }, 300);
  };

  toggleKeyboard = () => {
    this.setState({ keyboard: !this.state.keyboard });
  };

  render() {
    return (
      <div className="App">
        <Banner />
        {this.state.accessible && <AccessibleText />}
        <div className={`content-wrapper ${this.state.accessible ? "accessible" : ""}`}>
          <BrowserRouter>
            <SwitchPages />
          </BrowserRouter>
          <ScreenKeyboard visible={this.state.keyboard} />
        </div>
        <AccessibleButton onPress={this.toggleAccessible} />
        <KeyboardButton onPress={this.toggleKeyboard} />
      </div>
    );
  }
}

export default App;
