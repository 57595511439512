import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import FacilityMap from "./FacilityMap";
import { locationCode } from "../Utils/Common";

Modal.setAppElement("#root");

function UnitMapModal(props) {
  const [units, setUnits] = useState([]);
  const [floors, setFloors] = useState([]);
  const [visibleFloor, setVisibleFloor] = useState(null);

  useEffect(() => {
    loadLocationUnits();
  }, []);

  const loadLocationUnits = () => {
    axios
      .get(`/api/location_units?location_code=${locationCode()}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        let units = res.data;
        units = units.filter(u => u.left_coordinate !== 0 && u.top_coordinate !== 0)
        let floors = units.map((u) => u.floor_no);
        floors = [...new Set(floors)];
        setFloors(floors);
        setVisibleFloor(floors[0]);
        setUnits(units);
      });
  };

  return (
    <Modal isOpen={props.show} style={modalStyles}>
      <div className="modal-header">
        <h4>Facility Map</h4>
        <div onClick={props.onClose} className="modal-close-btn">
          <MdClose size="2em" />
        </div>
      </div>
      <div className="modal-content facility-map-modal-content">
        <div className="row justify-content-end">
          <div className="col-col-4 text-center align-self-end">
            {floors.map((f) => (
              <label key={f} className="mr-4">
                <input type="radio" className="mr-2" checked={visibleFloor === f} onClick={(e) => setVisibleFloor(f)} />
                Floor {f}
              </label>
            ))}
          </div>
        </div>
        {units.length > 0 ? (
          <FacilityMap units={units} activeUnits={props.unitNumbers || []} visibleFloor={visibleFloor} />
        ) : (
          <p className="text-center">Map is not available for this location.</p>
        )}
      </div>
      <div className="modal-footer">
        <button onClick={props.onClose} className="btn btn-pink">
          Close
        </button>
      </div>
    </Modal>
  );
}

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default UnitMapModal;
