import React, { Component } from "react";
import axios from "axios";
import { FaCheck } from "react-icons/fa";
import { BulletList } from "react-content-loader";
import BackButton from "../../shared/BackButton";
import { locationObject, locationCode, setUnitObject } from "../../Utils/Common";
import { chooseDiscount } from "../../shared/Unit";
import { logApiFail } from "../../Utils/rollbar";

class Units extends Component {
  constructor(props) {
    super(props);

    if (!locationObject()) {
      this.props.history.push("/");
    }

    this.state = {
      units: [],
      hasParking: false,
    };
  }

  getDiscount(unitTypeId) {
    const data = {
      location_code: locationCode(),
      unit_type_id: unitTypeId,
    };
    return axios
      .post("/api/ssm/GetAllDiscounts", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => chooseDiscount(response.data.SSM));
  }

  async loadUnitType(value) {
    if (value.Length[0] > 0 || value.Breadth[0] > 0) {
      var categoryName = value.CategoryName[0];
      var size = value.Length[0] * value.Breadth[0];
      var sizeString = "";

      if (categoryName.includes("Parking")) {
        sizeString = "PARKING";
      } else if (size <= 99) {
        sizeString = "SMALL";
      } else if (size >= 100 && size <= 199) {
        sizeString = "MEDIUM";
      } else if (size >= 200 && size <= 299) {
        sizeString = "LARGE";
      } else {
        sizeString = "EXTRA LARGE";
      }
      var discount = value.IsAvailable[0] === "1" ? await this.getDiscount(value.UnitTypeId[0]) : { discountId: 0 }; // Required for api

      return {
        // approximateSize: value.ApproximateSize[0],
        breadth: value.Breadth[0],
        categoryName: value.CategoryName[0],
        discount: value.Discount[0],
        // internetPrice: value.InternetPrice[0],
        // internetPriceAvailable: value.InternetPriceAvailable[0],
        isAvailable: value.IsAvailable[0],
        length: value.Length[0],
        rent: parseFloat(value.Rent[0]).toFixed(2),
        // unitTypeCode: value.UnitTypeCode[0],
        unitTypeDescription: value.UnitTypeDescription[0],
        unitTypeDetailedDescription: value.UnitTypeDetailedDescription[0],
        unitTypeId: value.UnitTypeId[0],
        sizeString,
        recommendedHighlight: value.InternetPriceAvailable[0] === "1" && this.state.locationContent?.recommended_unit,
        ...discount,
      };
    } else {
      return {};
    }
  }

  componentDidMount() {
    // Load location content
    axios
      .get(`/api/location_contents/${locationCode()}`)
      .then((res) => {
        this.setState({ locationContent: res.data });
        this.loadUnitTypes();
      })
      .catch((err) => {
        logApiFail(`/api/location_contents/${locationCode()}`, undefined, err);
        console.log("err", err);
      });
  }

  loadUnitTypes() {
    const data = {
      location_code: locationCode(),
      new_data: true, // TODO: load cache data if possible
    };
    axios
      .post("/api/ssm/GetLocationUnitTypes", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {
        const result = response.data.LocationUnitTypes.UnitType.map(async (value) => {
          return await this.loadUnitType(value);
        });
        let units = await Promise.all(result);

        const climateControl = sessionStorage.getItem("climate_control") === "true";

        if (climateControl) {
          units = units.filter((u) => u.categoryName.toLowerCase() === "climate controlled");
        }
        this.setState({ units });

        if (units.findIndex((e) => e.categoryName.includes("Parking")) > -1) {
          this.setState({ hasParking: true });
        }
      });
  }

  handleClickUnit = (unit) => {
    setUnitObject(unit);
    if (locationObject().kiosk_id_scanner_enabled) {
      this.props.history.push("/identity");
    } else {
      this.props.history.push("/account-information");
    }
  };

  handlePhone = () => {
    // window.open(`tel:${locationObject().phone}`)
  };

  tabItems = () => {
    let items = [];

    if (this.state.locationContent?.unit_group_large_to_small) {
      items = ["EXTRA LARGE", "LARGE", "MEDIUM", "SMALL"];
    } else {
      items = ["SMALL", "MEDIUM", "LARGE", "EXTRA LARGE"];
    }

    if (this.state.hasParking) {
      items.push("PARKING");
    }
    return items;
  };

  firstSizeGroupIndex = () => {
    let activeSizeGroups = this.state.units.map((u) => u.sizeString);
    activeSizeGroups = [...new Set(activeSizeGroups)];

    const firstActiveIndex = this.tabItems().findIndex((e) => activeSizeGroups.includes(e));

    return firstActiveIndex;
  };

  tabHeader = () => {
    return (
      <div className="row py-5">
        <div className="col-12">
          <div className="card p-0">
            <div className="card-body text-center p-0">
              <ul className="nav nav-tabs border-0 w-100 sizeSelectionTab" id="myTab" role="tablist">
                {this.tabItems().map((list, index) => (
                  <li className="nav-item" role="presentation" key={index}>
                    <a
                      className={index === this.firstSizeGroupIndex() ? "nav-link active" : "nav-link "}
                      data-toggle="tab"
                      href={`#` + list.replace(" ", "")}
                      role="tab"
                      aria-controls={list.replace(" ", "")}
                      aria-selected={index === this.firstSizeGroupIndex() ? "true" : "false"}
                    >
                      {list}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  tabBody = () => {
    return this.tabItems().map((tabName, index) => {
      let filteredUnits = [];
      filteredUnits = this.state.units.filter((unit) => unit.sizeString === tabName);

      if (this.state.locationContent?.unit_size_high_to_low) {
        filteredUnits.sort(
          (a, b) => b.isAvailable - a.isAvailable || b.length * b.breadth - a.length * a.breadth || b.length - a.length
        );
      } else {
        filteredUnits.sort(
          (b, a) => a.isAvailable - b.isAvailable || b.length * b.breadth - a.length * a.breadth || b.length - a.length
        );
      }

      return (
        <div
          key={index}
          className={index === this.firstSizeGroupIndex() ? "tab-pane fade show active" : "tab-pane fade"}
          id={tabName.replace(" ", "")}
          role="tabpanel"
        >
          {filteredUnits.length === 0 && (
            <h3 className="text-center">
              Can't find the right unit? Give us a call at <a href="tel:8337777273">833-777-7273</a>
            </h3>
          )}
          {filteredUnits.map(this.renderUnit)}
        </div>
      );
    });
  };

  renderUnitPrice = (unit, isAvailable) => {
    if (!isAvailable) {
      return <span className="text-secondary price">Call for Availability</span>;
    }

    if (unit.discountAmount > 0) {
      let price = unit.amount;
      if (unit.discountType === "1") {
        price = (unit.rent * (1 - unit.discountAmount / 100)).toFixed(2);
      }
      if (unit.discountType === "2") {
        price = parseFloat(unit.discountAmount).toFixed(2);
      }
      return (
        <div className="price">
          <span>
            <del className="strikethrough">${unit.rent}</del> <span>${price}</span>
            <small>/mo</small>
          </span>
          <p
            style={{
              color: "#000000",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {unit.discountName}
          </p>
          {unit.recommendedHighlight && <p className="text-danger mt-3 mb-0">Recommended Unit!</p>}
        </div>
      );
    } else {
      return (
        <div className="price">
          <span>${unit.rent}</span>
          <small>/mo</small>
          {unit.recommendedHighlight && <p className="text-danger mt-3 mb-0">Recommended Unit!</p>}
        </div>
      );
    }
  };

  renderUnit = (unit) => {
    const isAvailable = unit.isAvailable === "1";

    return (
      <div className="card mb-4" key={unit.unitTypeId}>
        <div className={`card-body ${unit.recommendedHighlight ? "highlight-unit" : ""}`}>
          <div className="row">
            <div className="col-2 text-center">
              <img
                src={unit.sizeString === "PARKING" ? "/asset/parking_icon.png" : "/asset/Group-370.png"}
                className=""
                width="80"
                alt=""
              />
            </div>
            <div className="col-3">
              <h2 className="text-black font-weight-bold mb-2">
                {unit.length}’ x {unit.breadth}’
              </h2>
              <h6>{unit.unitTypeDetailedDescription}</h6>
              <h5 className="text-black font-weight-bold mb-1 text-nowrap">
                <FaCheck className="text-danger mr-1" />
                {unit.categoryName}
              </h5>
            </div>
            <div className="col-4 d-flex align-items-center">{this.renderUnitPrice(unit, isAvailable)}</div>
            <div className="col-3 d-flex align-items-center">
              {isAvailable ? (
                <button className="btn btn-md btn-pink btn-block rent-btn" onClick={() => this.handleClickUnit(unit)}>
                  RENT NOW
                </button>
              ) : (
                <button className="btn btn-md btn-secondary btn-block rent-btn" onClick={this.handlePhone}>
                  {locationObject().phone}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="wrapper wrapper-flex">
          <div className="container">
            <div className="text-center">
              <h1 className="font-weight-bold text-danger text-center">
                {`${locationObject().city} Self Storage at `}
                <span className="text-danger">{locationObject().address}</span>
              </h1>
              <h1 className="font-weight-bold my-5">Select your unit</h1>
            </div>
            {this.tabHeader()}
            <div className="tab-content" id="myTabContent">
              {this.state.units.length !== 0 ? this.tabBody() : <BulletList backgroundColor={"#ccc"} />}
            </div>
          </div>
        </div>
        <BackButton backPath={this.props.location.backPath} />
      </React.Fragment>
    );
  }
}
export default Units;
