import React from "react";

function KeyboardButton(props) {
  return (
    <div className="bottom-nav keyboard-button">
      <div className="card" onClick={props.onPress}>
        <div className="card-body text-center" style={{ padding: "1rem" }}>
          <img src="/asset/keyboard-72.png" width="40px" alt="Keyboard" />
          <span className="text-black font-weight-bold pl-2">Keyboard</span>
        </div>
      </div>
    </div>
  );
}

export default KeyboardButton;
