import React, { Component, Fragment } from "react";
import axios from "axios";
import { setLocationObject } from "../../Utils/Common";

class WesternExpoSelect extends Component {
  state = {
    location_left: false,
    location_right: false,
  };

  selectLocation = (location) => {
    setLocationObject(location);
    this.props.history.push("/home");
  };

  componentDidMount() {
    const locationCodes = ["33", "31"]; // S0004, S0005

    locationCodes.forEach((code) => {
      axios.get("/api/locations?location_code=" + code + "&role=kiosk").then((response) => {
        const location = response.data;
        if (!location) return;

        // Locations should be arranged in order.
        if (location.location_code === "33") {
          this.setState({ location_left: location });
        } else {
          this.setState({ location_right: location });
        }
      });
    });
  }

  render() {
    const { location_left, location_right } = this.state;

    if (!location_left || !location_right) return false;

    return (
      <Fragment>
        <div className="wrapper wrapper-flex py-5">
          <div className="container">
            <div className="text-center mb-1">
              <h2 className="text-secondary font-weight-bold">
                This kiosk operates two facilities at this location.
                <br />
                <br />
                Touch the map or the facility description below
                <br />
                To select your desired facility!
                <br />
                <br />
              </h2>
            </div>
            <div className="row position-relative">
              <img src="/Western-Expo.jpg" width="600" height="1106" alt="Western-Expo" className="western-expo-img" />
              <div className="red-divide-line" />
              <div className="left-pane" onClick={() => this.selectLocation(location_left)} />
              <div className="right-pane" onClick={() => this.selectLocation(location_right)} />
            </div>
            <div className="row">
              <div className="col-sm-6 mb-4" onClick={() => this.selectLocation(location_left)} key="location_left">
                <div className="row py-4 px-2">
                  <div className="col-md-6">
                    <img
                      alt="Facility Location"
                      src={location_left.photo}
                      className="pointer"
                      width="200px"
                      height="125px"
                    />
                  </div>
                  <div className="col-md-6">
                    <h2 className="pointer mb-2">{location_left.name}</h2>
                    <div>
                      <p className="font-weight-bold text-muted pointer mb-2 text-capitalize">
                        {`${location_left.address}, ${location_left.city}, ${location_left.state} ${location_left.zip}`}
                        <br />
                        <span className="text-danger ml-0">See Details</span>
                      </p>
                      <br />
                      <h5>
                        SELF STORAGE
                        <br />
                        PARKING
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mb-4" onClick={() => this.selectLocation(location_right)} key="location_right">
                <div className="row py-4 px-2">
                  <div className="col-md-6">
                    <img
                      alt="Facility Location"
                      src={location_right.photo}
                      className="pointer"
                      width="200px"
                      height="125px"
                    />
                  </div>
                  <div className="col-md-6">
                    <h2 className="pointer mb-2">{location_right.name}</h2>
                    <div>
                      <p className="font-weight-bold text-muted pointer mb-2 text-capitalize">
                        {`${location_right.address}, ${location_right.city}, ${location_right.state} ${location_right.zip}`}
                        <br />
                        <span className="text-danger ml-0">See Details</span>
                      </p>
                      <br />
                      <h5>
                        SELF STORAGE
                        <br />
                        CLIMATE CONTROLLED
                        <br />
                        PARKING
                        <br />
                        CARPORT PARKING
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default WesternExpoSelect;
