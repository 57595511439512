import axios from "axios";
import moment from "moment";
import React from "react";
import {
  addPaymentFailureMessage,
  autopayBit,
  cardType,
  getTenantLease,
  locationCode,
  locationObject,
  pendingPaymentObject,
  sessionObject,
} from "../../../Utils/Common";
import { getTenantUnitLeaseDetails } from "../../../Utils/data";
import { confirmAlertShow } from "../../../Utils/paygateway";
import ProcessPaymentRetry from "../../../Utils/ProcessPaymentRetry";
import { waitCreditSaleFromRcm } from "../../../Utils/RCMLibrary";
import { logApiFail } from "../../../Utils/rollbar";

class OnlinePaymentEmv extends ProcessPaymentRetry {
  constructor(props) {
    super(props);
    this.state = {
      processingPayment: false,
      loginObj: sessionObject(),
      paymentObject: pendingPaymentObject(),
      profile: {},
    };
  }

  componentDidMount() {
    if (!locationObject()) {
      console.log("Location is not selected");
      this.props.history.push("/unit-lease-details");
      return;
    }

    const data = {
      location_code: locationCode(),
      tenant_id: this.state.loginObj.id,
    };
    axios
      .post("/api/ssm/GetTenantInfo", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.status === 200 && response.data.TenantDetails) {
          const tenant = response.data.TenantDetails.Tenant[0];
          const profile = {
            tenantId: tenant.TenantID[0],
            firstName: tenant.FirstName[0],
            middleName: tenant.MiddleName[0],
            lastName: tenant.LastName[0],
            address: tenant.Address[0],
            city: tenant.City[0],
            state: tenant.State[0],
            zip: tenant.ZipCode[0],
          };
          this.setState({ profile }, this.startPayment);
        }
      });

    // For testing use this on local environment

    // setTimeout(() => {
    //   this.processPayment(_testXLinkEMVResult);
    //   this.setState({ processingPayment: true });
    // }, 3000);
  }

  startPayment() {
    const amount = parseFloat(this.state.paymentObject.rent);
    const orderId = Math.floor(Math.random() * 10000000000) + "";

    waitCreditSaleFromRcm(amount, orderId)
      .then((res) => {
        console.log("RCM response =>", res);

        const result = res.XLinkEMVResult;
        if (result["RESULT"] === "0") {
          this.processPayment(res.XLinkEMVResult);
          this.setState({ processingPayment: true });
        } else {
          const resultMsg = result["RESULTMSG"] || "A server-side error has occurred from RCM.";
          sessionStorage.setItem("payment_failure_message", resultMsg);
          // eslint-disable-next-line no-throw-literal
          throw { error: resultMsg };
        }
      })
      .catch(async (err) => {
        await addPaymentFailureMessage(sessionObject().id);
        logApiFail("https://localsystem.paygateway.com", {amount, orderId}, err);
        this.props.history.push("/payment-failure", {
          retryPath: "/online-payment-emv",
          backPath: "/online-payment-method",
        });
      });
  }

  processPayment = (cardInfo) => {
    const { profile, paymentObject, loginObj } = this.state;
    const mylocation = locationObject();

    const requestBody = {
      location_code: locationCode(),
      tenant_id: loginObj.id,
      unit_id: paymentObject.unitID,
      amount: cardInfo.APPROVEDAMOUNT,
      pay_periods: parseInt(paymentObject.prePayPeriods),
      payment_method: "CC",
      cc_type: cardType(cardInfo.CARDBRAND, cardInfo.CARDBRANDSHORT),
      cc_no: cardInfo.ACCOUNT,
      cc_expiration_date: `${cardInfo.EXPMONTH}/${2000 + cardInfo.EXPYEAR * 1}`,
      cc_address: profile.address,
      cc_postal_code: profile.zip,
      cc_name: `${profile.firstName} ${profile.lastName}`,
      transaction_id: cardInfo.TRANSACTIONID,
      cc_response_code: cardInfo.HOSTRESPONSECODE,
      autopay_bit: autopayBit(),
      order_id: cardInfo.ORDERID,
      token: cardInfo.ALIAS,
      auction_proceeds: 0,
    };

    this.handleProcessPaymentAPI(
      requestBody,
      async () => {
        // success case
        const lease = getTenantLease();
        const tenantInfo = sessionObject();
        getTenantUnitLeaseDetails(locationCode(), loginObj.id, paymentObject.leaseNumber).then((newLease) => {
          const params = {
            full_name: `${tenantInfo.first}, ${tenantInfo.last}`,
            unit_number: lease.unitNumber,
            receipt_number: cardInfo.ORDERID,
            payment_date: moment().format("L"),
            amount_due_before_payment: lease.currentBalanceDue,
            account_balance_if_negative_after_payment: newLease.CurrentBalanceDue[0],
            amount_paid: cardInfo.APPROVEDAMOUNT,
            paid_thru_date: newLease.PaidThroughDate[0],
            location_phone: mylocation.phone,
            email: lease.tenantEMail,
          };
          axios.post("/api/notices/receipt", params);
        });
        this.props.history.push("/unit-lease-details#paid");
      },
      (result) => {
        // this will handle like incorrect digit check CC
        confirmAlertShow("", result);
      },
      () => {},
      true
    );
  };

  render() {
    return (
      <div className="wrapper wrapper-flex">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="text-center">
                {this.state.processingPayment ? (
                  <h1 className="font-weight-bold">Payment Processing</h1>
                ) : (
                  <h1 className="font-weight-bold">
                    Please tap or insert <br /> your credit card below and <br /> follow the instructions on the screen
                  </h1>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center mt-5">{this.renderCardSVG()}</div>
          </div>
        </div>
      </div>
    );
  }

  renderCardSVG() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="280" height="280" viewBox="0 0 560 560">
        <g id="Group_386" data-name="Group 386" transform="translate(-262 -428)">
          <circle
            id="Ellipse_84"
            data-name="Ellipse 84"
            cx="278.5"
            cy="278.5"
            r="278.5"
            transform="translate(262 428)"
            fill="#f0dde0"
          />
          <g
            id="Ellipse_83"
            data-name="Ellipse 83"
            transform="translate(311 477)"
            fill="#fff"
            stroke="#d50037"
            strokeWidth="10"
          >
            <circle cx="229.5" cy="229.5" r="229.5" stroke="none" />
            <circle cx="229.5" cy="229.5" r="224.5" fill="none" />
          </g>
          <g id="pay" transform="translate(430 569.132)">
            <g id="Group_382" data-name="Group 382" transform="translate(141.316 160.115)">
              <g id="Group_381" data-name="Group 381">
                <path
                  id="Path_1213"
                  data-name="Path 1213"
                  d="M323.435,261.393a18.633,18.633,0,0,0-20.934,0,18.422,18.422,0,0,0-10.441-3.2,18.685,18.685,0,0,0,0,37.371,18.423,18.423,0,0,0,10.441-3.2,18.687,18.687,0,1,0,20.934-30.961Zm-4.187,20.882a8.284,8.284,0,0,1-11.682,1.034,7.18,7.18,0,0,1-1.085-1.085,5,5,0,0,0-3.98-1.809,5.13,5.13,0,0,0-3.98,1.861,8.087,8.087,0,0,1-6.409,3,8.361,8.361,0,0,1-8.374-8.322,8.3,8.3,0,0,1,14.731-5.324,5.178,5.178,0,0,0,7.288.775,5.485,5.485,0,0,0,.775-.775,8.292,8.292,0,0,1,12.715,10.648Z"
                  transform="translate(-273.4 -258.175)"
                  fill="#d50037"
                />
              </g>
            </g>
            <g id="Group_384" data-name="Group 384" transform="translate(0 55.2)">
              <g id="Group_383" data-name="Group 383" transform="translate(0)">
                <path
                  id="Path_1214"
                  data-name="Path 1214"
                  d="M220.193,119.5c0-.465.207-.982.207-1.5s-.207-.982-.207-1.447V105.493a21.994,21.994,0,0,0-22.123-21.864h-6.306V77.374A22,22,0,0,0,169.952,55.2H21.916A22.082,22.082,0,0,0,0,77.374v90.869a21.817,21.817,0,0,0,21.761,21.864h6.151v6.254a22.215,22.215,0,0,0,22.071,22.174H198.071a22.293,22.293,0,0,0,22.123-22.174V143.743c0-.465.207-.982.207-1.447s-.207-.982-.207-1.447ZM27.912,105.493V179.77h-6a11.472,11.472,0,0,1-11.578-11.32V77.374A11.748,11.748,0,0,1,21.916,65.538H170a11.661,11.661,0,0,1,11.475,11.837v6.254H49.983A21.95,21.95,0,0,0,27.912,105.493Zm181.944,90.869A11.891,11.891,0,0,1,198.071,208.2H49.983a11.813,11.813,0,0,1-11.733-11.837V147.206H209.856Zm0-59.494H38.249V123.429H209.856Zm.052-23.777H38.249v-7.6A11.615,11.615,0,0,1,49.983,93.966h148.14a11.627,11.627,0,0,1,11.785,11.527v7.6Z"
                  transform="translate(0 -55.2)"
                  fill="#d50037"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default OnlinePaymentEmv;
