import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { NavLink } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import { locationCode, sessionObject, tenantId } from "../../../Utils/Common";
import { getTenantUnitLeaseDetails } from "../../../Utils/data";

function ScheduleMoveOut(props) {
  const [rating, setRating] = useState(0);
  const [error, setError] = useState("");

  const leaseNumber = sessionStorage.getItem("selected_lease_number");

  const [leaseData, setLeaseData] = useState({});

  useEffect(() => {
    loadTenantLeaseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTenantLeaseData = async () => {
    const leaseData = await getTenantUnitLeaseDetails(locationCode(), tenantId(), leaseNumber);
    setLeaseData(leaseData);
  };

  if (!leaseNumber) {
    props.history.push("/my-units");
    return false;
  }

  const showSuccessModal = () => {
    const accessible = sessionStorage.getItem("accessible") === "on";

    confirmAlert({
      title: "",
      message: <span>Thank you for renting with us! We hope you consider us for your future storage needs.</span>,
      overlayClassName: accessible ? "accessible" : "",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            props.history.push("/my-units");
          },
        },
        {
          label: "Logout",
          onClick: () => {
            // localStorage.clear();
            // sessionStorage.clear();
            window.location.replace("/tenant-login");
          },
        },
      ],
    });
  };

  const submitScheduleMoveOut = async () => {
    await disableAutopay();
    const moveOutDate = moment().add(1, "day").utc().format("L"); // Move out date should be today's date plus one.
    const data = {
      location_code: locationCode(),
      lease_number: leaseNumber,
      move_out_date: moveOutDate,
      vacate_reason_id: "1",
      custom_note: rating !== 0 ? `Customer experience - ${rating} stars` : "",
    };
    axios
      .post("/api/ssm/ScheduleMoveOut", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.ScheduleMoveOut &&
          response.data.ScheduleMoveOut.SaveSuccessful[0] === "True"
        ) {
          sendMoveOutEmail();
          showSuccessModal();
        } else {
          setError("Something went wrong. Failed to schedule move out.");
        }
      });
  };

  const sendMoveOutEmail = async () => {
    const tenantInfo = sessionObject();
    const data = {
      tenantEmail: tenantInfo.email,
      tenantName: tenantInfo.first,
    };
    await axios.post("/api/ssm/send-schedule-move-out-email", data);
  };

  const disableAutopay = async () => {
    const data = {
      service_name: "UpdateAutoPayInformation",
      xml_body: {
        strLocationCode: locationCode(),
        TenantId: tenantId(),
        CCType: "",
        CCNumber: "",
        CCAccountHolder: "",
        CCExpDate: "",
        CCBillAddress: "",
        CCBillZip: "",
        UnitId: leaseData.UnitID[0],
        strAutoCCProcessingDay: "0",
      },
    };
    await axios.post("/api/ssm", data);
  };

  return (
    <div className="wrapper wrapper-flex py-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-8 mx-auto">
            <div className="card">
              <div className="card-body">
                <div className="text-center mb-4">
                  <h4 className="mb-3">
                    Thank you for being a SpareBox Storage customer,
                    <br />
                    we hope you consider us for any future storage needs.
                  </h4>
                  <h5 className="mb-4">
                    Please disregard any system generated billing messages that may appear while the property manager
                    verifies your move-out. This can take up to 2-3 business days.
                    <br />
                    <br />
                    Please rate your overall rental experience. This is optional, but appreciated.
                  </h5>
                  <div className="rating-container">
                    <ReactStars onChange={setRating} size={48} activeColor="#D50037" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-center mb-2">
                    <NavLink className="btn btn-secondary rounded-pill px-4" to="/unit-lease-details">
                      Cancel
                    </NavLink>
                  </div>
                  <div className="col-md-6 text-center mb-2">
                    <button className="btn btn-danger rounded-pill px-4" onClick={submitScheduleMoveOut}>
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <h5 className="text-center mt-4 text-danger">{error}</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleMoveOut;
