import axios from "axios";
import { Component } from "react";
import XMLParser from "react-xml-parser";
import { convertLocalDateStrToDateMoment, finishExistingTenantNote, tenantId } from "./Common";
import { getLastPaymentDateTimeMoment, paymentRetryFailureAlert, SERVERS_TIME_DIFFERENCE } from "./paygateway";
import { logApiFail } from "./rollbar";
export class ProcessPaymentRetry extends Component {
  constructor(props) {
    super(props);
    this.retryCount = 0;
    this.timeoutId = null;
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      console.log("cleared timeout id");
      clearTimeout(this.timeoutId);
    }
  }

  handleProcessPaymentAPI = (requestBody, successCb, failedCb, retryFailedCb, isExistingTenantFlow = false) => {
    const beforePaymentTimeMoment = convertLocalDateStrToDateMoment(
      new Date(Date.now() - SERVERS_TIME_DIFFERENCE).toLocaleString("en-US", {
        timeZone: "America/Cancun",
      })
    );

    axios
      .post("/api/ssm/ProcessPayment_V2", JSON.stringify(requestBody), {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {
        const data = new XMLParser().parseFromString(response.data);
        const result = data.getElementsByTagName("PaymentMessage")[0].value;
        if (result === "Successful") {
          finishExistingTenantNote(tenantId(), "Credit Card");
          await successCb();
        } else {
          // additional check if payment has been processed
          if (result.includes("Unable to process the payment, For this Order Id payment has been processed")) {
            await successCb();
            return;
          }
          failedCb(result);
        }
      })
      .catch(async (e) => {
        if (e?.response?.data?.error.includes("Error: timeout")) {
          console.log("timeout");
          // timeout error
          const lastPaymentDateTimeMoment = await getLastPaymentDateTimeMoment(
            requestBody.location_code,
            requestBody.tenant_id
          );
          // lastPaymentDateTime is in America/Cancun time zone
          // convert to moment with America/Cancun timezone
          if (lastPaymentDateTimeMoment && lastPaymentDateTimeMoment.isAfter(beforePaymentTimeMoment)) {
            // no need for retry, already make last payment in case of time out
            console.log("payment already processed");
            await successCb();
            return;
          }
        }
        if (this.retryCount < 3) {
          this.timeoutId = setTimeout(() => {
            this.handleProcessPaymentAPI(requestBody, successCb, failedCb, retryFailedCb, isExistingTenantFlow);
            this.retryCount += 1;
          }, 3000);
        } else {
          logApiFail("ProcessPayment_V2", requestBody, e);
          if (!isExistingTenantFlow) {
            paymentRetryFailureAlert();
          }
          if (typeof retryFailedCb === "function") {
            retryFailedCb();
          }
        }
      });
  };
}

export default ProcessPaymentRetry;
