import { combineReducers } from "redux";
import accountReducer from "./Account/account.reducer";
import keyboardReducer from "./Keyboard/keyboard.reducer";

const rootReducer = combineReducers({
  account: accountReducer,
  keyboard: keyboardReducer,
});

export default rootReducer;
