import React from "react";

function AccessibleButton(props) {
  return (
    <div className="bottom-nav accessible-button">
      <div className="card" onClick={props.onPress}>
        <div className="card-body text-center">
          <img src="/asset/Group_167.png" width="30px" alt="Accessible" />
          <span className="text-black font-weight-bold pl-2">Accessible</span>
        </div>
      </div>
    </div>
  );
}

export default AccessibleButton;
