import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { convertLocalDateStrToDateMoment } from "./Common";
import { GetTenantLastPaymentDetails } from "./data";

export const MAX_RETRY_PAYMENT_COUNT = 2;
export const PAYMENT_ACTION_CANCEL = "cancel";
export const PAYMENT_RESPONSE_CODE_SUCCESS = "000";
export const SERVERS_TIME_DIFFERENCE = 35_000; // estimated time difference between client host and server host
const PAYMENT_RETRY_FAILED_ALERT_MESSAGE =
  "Unfortunately we have been unable to process your payment but your unit is still being held for you for 24 hours or until your requested move in date. When you are ready, you can complete your move in on our website, over the phone or using our on-site kiosk.";

export const confirmAlertShow = (title = "", message = "Message") => {
  confirmAlert({
    title,
    message,
    buttons: [
      {
        label: "OK",
        onClick: () => {},
      },
    ],
  });
};

export const paymentRetryFailureAlert = (
  title = "Payment retry failed",
  message = PAYMENT_RETRY_FAILED_ALERT_MESSAGE
) => {
  confirmAlertShow(title, message);
};

export const countPaymentRetry = (key, locationSearch, isRetry = false) => {
  const queryString = require("query-string");
  const searchParams = queryString.parse(locationSearch);
  const nRetry = sessionStorage.getItem(key);
  if (nRetry === null && (isRetry || searchParams.retry)) {
    sessionStorage.setItem(key, 0);
  } else if (nRetry !== null && (isRetry || searchParams.retry)) {
    sessionStorage.setItem(key, parseInt(nRetry) + 1);
    return parseInt(nRetry) + 1;
  }
  return 0;
};

export const resetNumberOfRetryPayment = (key) => {
  sessionStorage.removeItem(key);
};

export const handleCountRetryPaymentAch = (key, props, positiveCb, negativeCb) => {
  const nRetryPayment = countPaymentRetry(key, props.location.search, true);
  if (nRetryPayment >= MAX_RETRY_PAYMENT_COUNT) {
    paymentRetryFailureAlert();
    resetNumberOfRetryPayment(key);
    if (typeof positiveCb === "function") {
      positiveCb();
    }
  } else if (typeof negativeCb === "function") {
    negativeCb();
  }
};

export const isValidRoutingNumberAndAccountNumber = (routingNumber, accountNumber, setMessage) => {
  if (routingNumber === "") {
    setMessage("Routing number should not be empty.");
    return false;
  }
  if (accountNumber === "") {
    setMessage("Account number should not be empty.");
    return false;
  }
  return true;
};

export const isValidPayment = (result, paymentCancelCb, paymentFailedCallback) => {
  if (result.action === PAYMENT_ACTION_CANCEL) {
    paymentCancelCb();
    return false;
  }

  if (result.responseCode !== PAYMENT_RESPONSE_CODE_SUCCESS) {
    confirmAlertShow("", result.responseDescription);
    paymentFailedCallback();
    return false;
  }
  return true;
};

export const makePayment = (requestBody, failedCb, successCb) => {
  axios
    .post("/api/paygateway/directpay/express", JSON.stringify(requestBody), {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      if (response.data.STATE[0] === "ERROR") {
        confirmAlertShow("", "Payment Failed");
        failedCb();
        return false;
      }
      successCb(response.data);
    });
};

export const getLastPaymentDateTimeMoment = async (locationCode, tenantId) => {
  const lastPaymentDetails = await GetTenantLastPaymentDetails(locationCode, tenantId);
  if (lastPaymentDetails?.Unit?.[0].PaymentDateTime?.[0]) {
    return convertLocalDateStrToDateMoment(lastPaymentDetails?.Unit?.[0].PaymentDateTime?.[0]);
  }
  return null;
};

export const getLastPaymentDetails = async (locationCode, tenantId) => {
  const lastPaymentDetails = await GetTenantLastPaymentDetails(locationCode, tenantId);
  if (lastPaymentDetails?.Unit?.[0]) {
    return lastPaymentDetails?.Unit?.[0];
  }
  return null;
};
