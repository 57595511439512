import React, { Component, Fragment } from "react";
import axios from "axios";
import { Code } from "react-content-loader";
import { confirmAlert } from "react-confirm-alert";
import _ from "underscore";
import moment from "moment";
import { parseString } from "xml2js";
import { FcCalendar, FcMoneyTransfer, FcHome, FcAbout } from "react-icons/fc";

import {
  locationCode,
  setPendingPaymentObject,
  tenantId,
  setAutopayBit,
  setTenantLease,
  getInsuranceType,
  formatCurrency,
} from "../../../Utils/Common";
import { getUnitInfo } from "../../../Utils/data";
import BackButton from "../../../shared/BackButton";

class UnitLeaseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitLease: {
        tenantName: "",
        tenantAddress: "",
        tenantCity: "",
        tenantStateCode: "",
        countryID: "",
        zipCode: "",
        countryCode: "",
        homePhoneNumber: "",
        workPhoneNumber: "",
        workPhoneExtn: "",
        businessAccount: "",
        billingType: "",
        billingCycle: "",
        scheduledMoveoutDate: "",
        unitID: "",
        unitNumber: "",
        unitTypeCode: "",
        nextBillingDate: "",
        moveInDate: "",
        moveOutDate: "",
        paidThroughDate: "",
        leaseStatus: "",
        currentBalanceDue: "",
        tenantEMail: "",
        cellPhoneNumber: "",
        gateCode: "",
        comboCode: "",
      },
      leaseNumber: sessionStorage.getItem("selected_lease_number"),
      prePay: false,
      errorMsg: "",
    };
  }

  componentDidMount() {
    this.getTenantUnitDetails();

    if (this.props.location.hash === "#paid") {
      this.setState({ errorMsg: "Payment Successfully Completed!" });
    }
  }

  getTenantUnitDetails = () => {
    const data = {
      location_code: locationCode(),
      tenant_id: tenantId(),
      lease_no: this.state.leaseNumber,
    };
    axios
      .post("/api/ssm/GetTenantUnitLeaseDetails", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const details = response.data.TenantUnitDetails;
        this.setState({
          unitLease: {
            tenantName: details.TenantName[0],
            tenantAddress: details.TenantAddress[0],
            tenantCity: details.TenantCity[0],
            tenantStateCode: details.TenantStateCode[0],
            countryID: details.CountryID[0],
            zipCode: details.ZipCode[0],
            countryCode: details.CountryCode[0],
            homePhoneNumber: details.HomePhoneNumber[0],
            workPhoneNumber: details.WorkPhoneNumber[0],
            workPhoneExtn: details.WorkPhoneExtn[0],
            businessAccount: details.BusinessAccount[0],
            billingType: details.BillingType[0],
            billingCycle: details.BillingCycle[0],
            scheduledMoveoutDate: details.ScheduledMoveoutDate[0],
            unitID: details.UnitID[0],
            unitNumber: details.UnitNumber[0],
            unitTypeCode: details.UnitTypeCode[0],
            nextBillingDate: details.NextBillingDate[0],
            moveInDate: details.MoveInDate[0],
            moveOutDate: details.MoveOutDate[0],
            paidThroughDate: details.PaidThroughDate[0],
            leaseStatus: details.LeaseStatus[0],
            currentBalanceDue: details.CurrentBalanceDue[0],
            tenantEMail: details.TenantEMail[0],
            cellPhoneNumber: details.CellPhoneNumber[0],
            gateCode: details.GateCode[0],
            comboCode: details.ComboCode[0],
          },
        });
        const currentBalance = details.CurrentBalanceDue[0];

        if (currentBalance <= 0) {
          this.getPrepayDetails(details.PaidThroughDate[0]);
        }
      });
  };

  getPrepayDetails = (paidThroughDate) => {
    const prePayData = {
      location_code: locationCode(),
      lease_no: this.state.leaseNumber,
      period: "1",
      date: paidThroughDate,
    };

    axios
      .post("/api/ssm/GetPrePayRentalCharges", JSON.stringify(prePayData), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        parseString(response.data, { trim: true }, (err, result) => {
          const isWiseDues = result.Main.PeriodWiseDues.length > 0 && !!result.Main.PeriodWiseDues[0].Dues1;

          const detail =
            (result.Main.PrePayOldDues.length > 0 && result.Main.PrePayOldDues[0].Dues1) ||
            (result.Main.PeriodWiseDues.length > 0 && result.Main.PeriodWiseDues[0].Dues1);

          this.setState({
            prePay: {
              rent: detail && detail[0].Rent[0],
              insurance: detail && detail[0].Insurance[0],
              fee: detail && detail[0].Fees[0],
              discount: detail && detail[0].Discount[0],
              tax:
                detail &&
                (isWiseDues
                  ? detail[0].TaxTotal[0]
                  : detail[0].TaxonRent[0] * 1 + detail[0].TaxonIns[0] * 1 + detail[0].TaxonFees[0] * 1),
              prePayPeriods: result.Main.PrePayPeriods[0],
            },
          });
        });
      });
  };

  handlePayment = async (payType) => {
    let rentAmount = 0;
    let prePayPeriods = 0;

    if (payType === "prepay") {
      rentAmount = this.prepayAmount();
      prePayPeriods = this.state.prePay.prePayPeriods;
    } else {
      rentAmount = this.state.unitLease.currentBalanceDue;
      prePayPeriods = 0;
    }

    const unitInfo = await getUnitInfo(locationCode(), this.state.unitLease.unitNumber);

    if (unitInfo.IsAuctionPrepped[0] === "True") {
      this.setState({
        errorMsg: "Please contact customer service to make a payment: 833-777-7273",
      });
      return;
    }

    const obj = {
      rent: rentAmount,
      prePayPeriods: prePayPeriods,
      leaseNumber: this.state.leaseNumber,
      unitID: this.state.unitLease.unitID,
    };

    setPendingPaymentObject(obj);
    setTenantLease(this.state.unitLease);

    const accessible = sessionStorage.getItem("accessible") === "on";
    const paidThruDate = moment(this.state.unitLease.paidThroughDate, "M/D/YYYY");
    const lastDelinquentDate = moment().subtract(70, "days");

    if (paidThruDate.isBefore(lastDelinquentDate)) {
      confirmAlert({
        title: "",
        overlayClassName: accessible ? "accessible" : "",
        message:
          "Your account is over 70 days delinquent, please call 833-777-7273 and select option 3 to get more details on your account.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
      });

      return;
    }

    confirmAlert({
      title: "",
      message: "Enable Automatic Monthly Payments?",
      overlayClassName: accessible ? "accessible" : "",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setAutopayBit("1");
            this.props.history.push("/online-payment-method");
          },
        },
        {
          label: "No",
          onClick: () => {
            setAutopayBit("0");
            this.props.history.push("/online-payment-method");
          },
        },
      ],
    });
  };

  handleScheduleMoveOut = () => {
    const accessible = sessionStorage.getItem("accessible") === "on";

    confirmAlert({
      title: "",
      message: (
        <span>
          Your unit must be swept clean and ready to rent before moving out. If you leave any items behind, you will
          continue to be billed and may be charged a cleaning fee of up to $500. <br /> <b>Continue with move out?</b>
        </span>
      ),
      overlayClassName: accessible ? "accessible" : "",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.history.push("/schedule-move-out");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  prepayAmount = () => {
    return (
      parseFloat(this.state.prePay.rent || 0) +
      parseFloat(this.state.prePay.insurance || 0) +
      parseFloat(this.state.prePay.fee || 0) -
      parseFloat(this.state.prePay.discount || 0) +
      parseFloat(this.state.prePay.tax || 0)
    );
  };

  render() {
    return (
      <Fragment>
        <div className="wrapper wrapper-flex py-5">
          <div className="container">
            {this.state.unitLease.tenantName === "" ? (
              <Code backgroundColor={"#ccc"} />
            ) : (
              <div className="row">
                <div className="col-12 mb-4 text-center">
                  <h4 className="text-danger">{this.state.errorMsg}</h4>
                </div>
                {this.state.unitLease.currentBalanceDue <= 0 && this.state.prePay && (
                  <div className="col-md-12 mb-4">
                    <div className="card">
                      <div className="card-body text-center">
                        <h4>Prepay Next Month’s Rent</h4>
                        <p className="text-danger">
                          Next Month's Dues:
                          {formatCurrency(this.prepayAmount())}
                        </p>
                        <div
                          className="text-left mb-2"
                          style={{
                            margin: "0 auto",
                            width: "180px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <p className="mb-1">
                            Monthly Rent:
                            {formatCurrency(this.state.prePay.rent || 0)}
                          </p>
                          <p className="mb-1">
                            {getInsuranceType()}:{formatCurrency(this.state.prePay.insurance || 0)}
                          </p>
                          {this.state.prePay.fee !== "0" && (
                            <p className="mb-1">
                              Fee:
                              {formatCurrency(this.state.prePay.fee || 0)}
                            </p>
                          )}
                          <p className="mb-1">
                            Discount:
                            {formatCurrency(this.state.prePay.discount || 0)}
                          </p>
                          <p className="mb-1">
                            Tax:
                            {formatCurrency(this.state.prePay.tax || 0)}
                          </p>
                        </div>
                        <button
                          onClick={() => this.handlePayment("prepay")}
                          className="btn btn-danger btn-md rounded-pill px-3 mr-3"
                        >
                          Pay Now
                        </button>
                        <button
                          onClick={this.handleScheduleMoveOut}
                          className="btn btn-secondary btn-md rounded-pill px-3"
                        >
                          Move Out
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.unitLease.currentBalanceDue > 0 && (
                  <div className="col-md-12 mb-4">
                    <div className="card">
                      <div className="card-body text-center">
                        <h4>Pay Dues</h4>
                        <p>
                          Current Balance Due:
                          {formatCurrency(this.state.unitLease.currentBalanceDue)}
                        </p>
                        <button
                          onClick={() => this.handlePayment("due")}
                          className="btn btn-danger btn-md rounded-pill px-3"
                        >
                          Pay Now
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-3 mb-4 stretch-card">
                  <div className="card">
                    <div className="card-body text-center">
                      <div style={{ fontSize: "60px" }}>
                        <FcHome />
                      </div>
                      <h5>Gate Code</h5>
                      <p>{this.state.unitLease.gateCode}</p>
                      {this.state.unitLease.comboCode !== "0000" &&
                        !_.isEmpty(this.state.unitLease.comboCode.trim()) &&
                        (this.state.unitLease.leaseStatus === "Current" ||
                          this.state.unitLease.leaseStatus === "Pre-Paid") && (
                          <p>Overlock Code: {this.state.unitLease.comboCode}</p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4 stretch-card">
                  <div className="card">
                    <div className="card-body text-center">
                      <div style={{ fontSize: "60px" }}>
                        <FcAbout />
                      </div>
                      <h5>Lease Status</h5>
                      {!_.isEmpty(this.state.unitLease.scheduledMoveoutDate.trim()) ? (
                        <>
                          <p>Move Out Scheduled</p>
                          <p>{moment(this.state.unitLease.scheduledMoveoutDate).format("L")}</p>
                        </>
                      ) : (
                        <p>{this.state.unitLease.leaseStatus}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4 stretch-card">
                  <div className="card">
                    <div className="card-body text-center">
                      <div style={{ fontSize: "60px" }}>
                        <FcMoneyTransfer />
                      </div>
                      <h5>Current Balance Due</h5>
                      <p>{formatCurrency(this.state.unitLease.currentBalanceDue)}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4 stretch-card">
                  <div className="card">
                    <div className="card-body text-center">
                      <div style={{ fontSize: "60px" }}>
                        <FcCalendar />
                      </div>
                      <h5>Paid Through Date</h5>
                      <p>{this.state.unitLease.paidThroughDate}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <BackButton backPath="/my-units" />
      </Fragment>
    );
  }
}

export default UnitLeaseDetails;
