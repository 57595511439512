const INITIAL_STATE = {
  inputNames: [],
  currentInput: "",
  currentValue: "",
};

export const actionTypes = {
  SET: "KEYBOARD/SET",
  INIT: "KEYBOARD/INIT",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.INIT:
      return {
        ...state,
        inputNames: action.payload,
        // inputName: "",
        // currentValue: "",
      };
    case actionTypes.SET:
      return {
        ...state,
        currentInput: action.payload.name,
        currentValue: action.payload.value,
      };
    default:
      return state;
  }
};

export default reducer;
