import React from "react";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <div className="wrapper wrapper-flex">
      <div className="container my-4">
        <div className="d-flex align-items-center justify-content-center">
          <NavLink to="/home" className="btn btn-pink btn-lg btn-block col-4">
            Start
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Home;
