import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
import ProfileMenu from "./Profile/ProfileMenu";
import axios from "axios";
import { locationObject, locationCode, sessionObject, tenantId } from "../../Utils/Common";

class MyActivities extends Component {
  constructor(props) {
    super(props);
    if (!sessionObject()) {
      this.props.history.push("/");
    }
    this.state = {
      profile: {
        tenantId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        eMail: "",
        homePhone: "",
        workPhone: "",
        cellPhone: "",
        companyName: "",
        companyAddress: "",
        companyCity: "",
        companyState: "",
        companyZipCode: "",
        companyEMail: "",
        companyPhone: "",
        dateOfBirth: "",
        ssn: "",
        crivingLicenseID: "",
        drivingLicenseExpDate: "",
        drivingLicenseState: "",
        countryID: "",
        companyCountryId: "",
        success: "",
      },
      locationObject: locationObject(),
    };
  }
  componentDidMount() {
    const data = {
      location_code: locationCode(),
      tenant_id: tenantId(),
    };
    axios
      .post("/api/ssm/GetTenantInfo", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const profile = {
          tenantId: response.data.TenantDetails.Tenant[0].TenantID[0],
          firstName: response.data.TenantDetails.Tenant[0].FirstName[0],
          middleName: response.data.TenantDetails.Tenant[0].MiddleName[0],
          lastName: response.data.TenantDetails.Tenant[0].LastName[0],
          address: response.data.TenantDetails.Tenant[0].Address[0],
          city: response.data.TenantDetails.Tenant[0].City[0],
          state: response.data.TenantDetails.Tenant[0].State[0],
          zipCode: response.data.TenantDetails.Tenant[0].ZipCode[0],
          eMail: response.data.TenantDetails.Tenant[0].EMail[0],
          homePhone: response.data.TenantDetails.Tenant[0].HomePhone[0],
          workPhone: response.data.TenantDetails.Tenant[0].WorkPhone[0],
          cellPhone: response.data.TenantDetails.Tenant[0].CellPhone[0],
          companyName: response.data.TenantDetails.Tenant[0].CompanyName[0],
          companyAddress: response.data.TenantDetails.Tenant[0].CompanyAddress[0],
          companyCity: response.data.TenantDetails.Tenant[0].CompanyCity[0],
          companyState: response.data.TenantDetails.Tenant[0].CompanyState[0],
          companyZipCode: response.data.TenantDetails.Tenant[0].CompanyZipCode[0],
          companyEMail: response.data.TenantDetails.Tenant[0].CompanyEMail[0],
          companyPhone: response.data.TenantDetails.Tenant[0].CompanyPhone[0],
          dateOfBirth: response.data.TenantDetails.Tenant[0].DateOfBirth[0],
          ssn: response.data.TenantDetails.Tenant[0].SSN[0],
          drivingLicenseID: response.data.TenantDetails.Tenant[0].DrivingLicenseID[0],
          drivingLicenseExpDate: response.data.TenantDetails.Tenant[0].DrivingLicenseExpDate[0],
          drivingLicenseState: response.data.TenantDetails.Tenant[0].DrivingLicenseState[0],
          countryID: response.data.TenantDetails.Tenant[0].CountryID[0],
          companyCountryId: response.data.TenantDetails.Tenant[0].CompanyCountryId[0],
        };
        this.setState({ profile });
      });
  }
  render() {
    return (
      <div className="wrapper wrapper-flex py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-4 order-2 order-lg-1">
              <ProfileMenu />
            </div>
            <div className="col-lg-8 mb-4 order-1 order-lg-2">
              <div className="row">
                <div className="col-md-12 info-text">
                  <h4>Manage My Account</h4>
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card profile-container rounded-0 border-0">
                    <div className="card-body">
                      <div className="row account-container">
                        <div className="col-md-6 mb-4">
                          <h5 className="text-danger">
                            Contact Information
                            {/* <NavLink to="/update-profile" className="link-text">
                              Edit
                            </NavLink> */}
                          </h5>
                          <p>{`${this.state.profile.firstName} ${this.state.profile.lastName}`}</p>
                          <p>{this.state.profile.eMail}</p>
                          <p>{this.state.profile.cellPhone}</p>
                        </div>
                        <div className="col-md-6 mb-4">
                          <h5 className="text-danger">
                            Addresses
                            {/* <NavLink to="/update-profile" className="link-text">
                              Edit
                            </NavLink> */}
                          </h5>
                          <p>{this.state.profile.address}</p>
                          <p>{`${this.state.profile.city} ${this.state.profile.state}`}</p>
                          <p>{this.state.profile.zipCode}</p>
                        </div>
                        <div className="col-md-6 mb-4">
                          <h5 className="text-danger">Payment Information</h5>
                        </div>
                        <div className="col-md-6 mb-4">
                          <h5 className="text-danger">
                            Login Information
                            {/* <NavLink to="/update-profile" className="link-text">
                              Edit
                            </NavLink> */}
                          </h5>
                          <p>{`${this.state.profile.firstName} ${this.state.profile.lastName}`}</p>
                          <p>Username: {this.state.profile.eMail}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyActivities;
