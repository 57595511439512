import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import XMLParser from "react-xml-parser";

import { waitCreditSaleFromRcm } from "../../../Utils/RCMLibrary";
import {
  amountDue,
  insuranceId,
  reservationObject,
  reservedUnitObject,
  contactObject,
  locationCode,
  cardType,
  autopayBit,
  getInsuranceStatus,
  getGateCode,
  addPaymentFailureMessage,
  finishNewTenantNote,
  finalDiscountId,
} from "../../../Utils/Common";
import {
  countPaymentRetry,
  MAX_RETRY_PAYMENT_COUNT,
  paymentRetryFailureAlert,
  resetNumberOfRetryPayment,
} from "../../../Utils/paygateway";
import { logApiFail } from "../../../Utils/rollbar";
// import { addTenantNotes } from '../../../Utils/data';
// import { getTenantUnitLeaseDetails, testXLinkEMVResult as _testXLinkEMVResult } from "../../../Utils/data";

class ProcessPaymentEmv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processingPayment: false,
      paygateway: {},
      nRetryPayment: 0,
    };
  }

  componentDidMount() {
    const nRetryPayment = countPaymentRetry(
      "new_tenant_checkout_process_payment_emv_retry_count",
      this.props.location.search
    );
    this.setState({ nRetryPayment });

    const amount = amountDue();
    const orderId = Math.floor(Math.random() * 10000000000) + ""; // random order id

    waitCreditSaleFromRcm(amount, orderId)
      .then((res) => {
        console.log("RCM response =>", res);

        const result = res.XLinkEMVResult;
        if (result && result["RESULT"] === "0") {
          this.processPayment(res.XLinkEMVResult);
          this.setState({ processingPayment: true });
        } else {
          const resultMsg = result["RESULTMSG"] || "A server-side error has occurred from RCM.";
          sessionStorage.setItem("payment_failure_message", resultMsg);
          // eslint-disable-next-line no-throw-literal
          throw { error: resultMsg };
        }
      })
      .catch(async (err) => {
        logApiFail("https://localsystem.paygateway.com", {amount, orderId}, err);
        if (this.state.nRetryPayment >= MAX_RETRY_PAYMENT_COUNT) {
          paymentRetryFailureAlert();
          resetNumberOfRetryPayment("new_tenant_checkout_process_payment_emv_retry_count");
          this.props.history.push("/lease-payment");
          return;
        }
        await addPaymentFailureMessage(reservationObject().tenantId);

        this.props.history.push("/payment-failure", {
          retryPath: "/process-payment-emv?retry=true",
          backPath: "/lease-payment",
        });
      });

    // For testing use this on local environment

    // setTimeout(() => {
    //   this.processPayment(_testXLinkEMVResult);
    //   this.setState({ processingPayment: true });
    // }, 3000);
  }

  processPayment(cardInfo) {
    const reservationDetails = reservationObject();
    const unitData = reservedUnitObject();
    const contact = contactObject();

    const data = {
      location_code: locationCode(),
      tenant_id: reservationDetails.tenantId,
      gate_code: getGateCode(),
      unit_id: unitData.uId,
      insurance_id: insuranceId(),
      insurance_status_id: getInsuranceStatus(),
      discount_id: finalDiscountId(),
      start_date: moment().format("L"),
      end_date: moment().add(29, "days").format("L"),
      amount: parseFloat(cardInfo.APPROVEDAMOUNT).toFixed(2),
      payment_method: "CC",
      cc_type: cardType(cardInfo.CARDBRAND, cardInfo.CARDBRANDSHORT),
      cc_no: cardInfo.ACCOUNT, // TODO: address name correctly
      cc_expiration_date: `${cardInfo.EXPMONTH}/${2000 + cardInfo.EXPYEAR * 1}`,
      cc_address: `${contact.address}, ${contact.city}, ${contact.state}`,
      cc_postal_code: contact.zip,
      cc_name: `${contact.first} ${contact.last}`,
      transaction_id: cardInfo.TRANSACTIONID,
      cc_response_code: cardInfo.HOSTRESPONSECODE,
      res_code: reservationDetails.reservationCode,
      dbl_reservation_paid_fee: "0.00",
      autopay_bit: autopayBit(),
      order_id: cardInfo.ORDERID,
      token: cardInfo.ALIAS,
      doc_ref_number: "",
      doc_base64_format: localStorage.getItem("signed_lease_pdf") || "",
    };

    axios
      .post("/api/ssm/ConvertReservationtoRentalAndSendLeaseAgreement", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        let data = new XMLParser().parseFromString(response.data);
        console.log("SSM API response => ", data);

        const resultMsg = data.getElementsByTagName("ConvertReservationToMoveInMessage")[0].value;

        if (resultMsg === "Successful") {
          const leaseNumber = data.getElementsByTagName("ConvertReservationToMoveInLeaseNumber")[0].value;
          sessionStorage.setItem("created_lease_number", leaseNumber);
          finishNewTenantNote(reservationObject().tenantId, "RCM Card Reader");
          this.props.history.push("/movein-done");
        } else {
          sessionStorage.setItem("payment_failure_message", resultMsg);
          throw data;
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper wrapper-flex">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="text-center">
                  {this.state.processingPayment ? (
                    <h1 className="font-weight-bold">Payment Processing</h1>
                  ) : (
                    <h1 className="font-weight-bold">
                      Please tap or insert <br /> your credit card below and <br /> follow the instructions on the
                      screen
                    </h1>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center mt-5">{this.renderCardSVG()}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderCardSVG() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="280" height="280" viewBox="0 0 560 560">
        <g id="Group_386" data-name="Group 386" transform="translate(-262 -428)">
          <circle
            id="Ellipse_84"
            data-name="Ellipse 84"
            cx="278.5"
            cy="278.5"
            r="278.5"
            transform="translate(262 428)"
            fill="#f0dde0"
          />
          <g
            id="Ellipse_83"
            data-name="Ellipse 83"
            transform="translate(311 477)"
            fill="#fff"
            stroke="#d50037"
            strokeWidth="10"
          >
            <circle cx="229.5" cy="229.5" r="229.5" stroke="none" />
            <circle cx="229.5" cy="229.5" r="224.5" fill="none" />
          </g>
          <g id="pay" transform="translate(430 569.132)">
            <g id="Group_382" data-name="Group 382" transform="translate(141.316 160.115)">
              <g id="Group_381" data-name="Group 381">
                <path
                  id="Path_1213"
                  data-name="Path 1213"
                  d="M323.435,261.393a18.633,18.633,0,0,0-20.934,0,18.422,18.422,0,0,0-10.441-3.2,18.685,18.685,0,0,0,0,37.371,18.423,18.423,0,0,0,10.441-3.2,18.687,18.687,0,1,0,20.934-30.961Zm-4.187,20.882a8.284,8.284,0,0,1-11.682,1.034,7.18,7.18,0,0,1-1.085-1.085,5,5,0,0,0-3.98-1.809,5.13,5.13,0,0,0-3.98,1.861,8.087,8.087,0,0,1-6.409,3,8.361,8.361,0,0,1-8.374-8.322,8.3,8.3,0,0,1,14.731-5.324,5.178,5.178,0,0,0,7.288.775,5.485,5.485,0,0,0,.775-.775,8.292,8.292,0,0,1,12.715,10.648Z"
                  transform="translate(-273.4 -258.175)"
                  fill="#d50037"
                />
              </g>
            </g>
            <g id="Group_384" data-name="Group 384" transform="translate(0 55.2)">
              <g id="Group_383" data-name="Group 383" transform="translate(0)">
                <path
                  id="Path_1214"
                  data-name="Path 1214"
                  d="M220.193,119.5c0-.465.207-.982.207-1.5s-.207-.982-.207-1.447V105.493a21.994,21.994,0,0,0-22.123-21.864h-6.306V77.374A22,22,0,0,0,169.952,55.2H21.916A22.082,22.082,0,0,0,0,77.374v90.869a21.817,21.817,0,0,0,21.761,21.864h6.151v6.254a22.215,22.215,0,0,0,22.071,22.174H198.071a22.293,22.293,0,0,0,22.123-22.174V143.743c0-.465.207-.982.207-1.447s-.207-.982-.207-1.447ZM27.912,105.493V179.77h-6a11.472,11.472,0,0,1-11.578-11.32V77.374A11.748,11.748,0,0,1,21.916,65.538H170a11.661,11.661,0,0,1,11.475,11.837v6.254H49.983A21.95,21.95,0,0,0,27.912,105.493Zm181.944,90.869A11.891,11.891,0,0,1,198.071,208.2H49.983a11.813,11.813,0,0,1-11.733-11.837V147.206H209.856Zm0-59.494H38.249V123.429H209.856Zm.052-23.777H38.249v-7.6A11.615,11.615,0,0,1,49.983,93.966h148.14a11.627,11.627,0,0,1,11.785,11.527v7.6Z"
                  transform="translate(0 -55.2)"
                  fill="#d50037"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps)(ProcessPaymentEmv);
