import React, { useEffect, useState, useRef } from "react";
import BackButton from "../../../shared/BackButton";
import { NavLink } from "react-router-dom";
import { setContactObject } from "../../../Utils/Common";

const IdentityForm = (props) => {
  const [showManualButton, setShowManualButton] = useState(false);

  const honeywell = useRef("");

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  useEffect(() => {
    setTimeout(() => setShowManualButton(true), 7000);
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      processHoneywell(honeywell.current);
    } else if (e.key.length === 1) {
      honeywell.current = honeywell.current + e.key;
    }
  };

  const processHoneywell = (result) => {
    const info = result.split(",");

    const profile = {
      first: info[0],
      last: info[1],
      address: info[2],
      city: info[4],
      state: info[5],
      zip: info[6],
      driver_license_number: info[7],
    };
    setContactObject(profile);

    props.history.push("/account-information");
  };

  return (
    <React.Fragment>
      <div className="wrapper wrapper-flex">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="text-center">
                <h3 className="font-weight-bold">
                  Please place your Driver's License
                  <br />
                  or Photo ID on the scanner
                  <br />
                  with the photo facing up
                  <br />
                  until you hear a beep
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center mt-5 mb-5">
              <img src="/asset/Identity.png" alt="Identity" className="img-fluid" width="150" />
            </div>
          </div>
          <div className="row">
            {showManualButton && (
              <div className="col-12 text-center mt-5">
                <NavLink to="/account-information" className="btn btn-pink btn-md btn-lg" style={{ width: "250px" }}>
                  Enter Information Manually
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
      <BackButton backPath="/units" />
    </React.Fragment>
  );
};

export default IdentityForm;
