import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import BackButton from "../../../shared/BackButton";
import { translateFailureMessage } from "../../../Utils/Common";

class PaymentFailure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      retryPath: this.props.history.location.state ? this.props.history.location.state.retryPath : "/",
      backPath: this.props.history.location.state ? this.props.history.location.state.backPath : "/",
    };
  }

  render() {
    const message = sessionStorage.getItem("payment_failure_message") || "";
    const reasonMessage = translateFailureMessage(message);

    return (
      <React.Fragment>
        <div className="wrapper wrapper-flex">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="text-center">
                  <h1 className="font-weight-bold">
                    Oops! Payment failed
                    <br />
                    Please try again
                  </h1>
                  <h3>{reasonMessage}</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center mt-5">{this.renderSVG()}</div>
            </div>
            <div className="row">
              <div className="col-12 text-center mt-5">
                <NavLink to={this.state.retryPath} className="btn btn-pink btn-lg letter-spacing-2">
                  Retry
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <BackButton backPath={this.state.backPath} />
      </React.Fragment>
    );
  }

  renderSVG = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 560 560">
        <g id="Group_582" data-name="Group 582" transform="translate(-262 -493)">
          <circle
            id="Ellipse_84"
            data-name="Ellipse 84"
            cx="278.5"
            cy="278.5"
            r="278.5"
            transform="translate(262 493)"
            fill="#f0dde0"
          />
          <g
            id="Ellipse_83"
            data-name="Ellipse 83"
            transform="translate(311 542)"
            fill="#fff"
            stroke="#d50037"
            strokeWidth="10"
          >
            <circle cx="229.5" cy="229.5" r="229.5" stroke="none" />
            <circle cx="229.5" cy="229.5" r="224.5" fill="none" />
          </g>
          <g id="bill" transform="translate(420 716.239)">
            <path
              id="Path_1215"
              data-name="Path 1215"
              d="M196.507,35.466V29.555A29.588,29.588,0,0,0,166.952,0H84.2A17.753,17.753,0,0,0,66.466,17.733V142.651H31v59.11H208.329v-59.11H172.863V35.466ZM78.288,17.733a5.911,5.911,0,0,1,11.822,0V35.466h70.932V166.3H78.288Zm118.219,136.74v35.466H42.822V154.473H66.466V166.3H54.644v11.822H184.685V166.3H172.863V154.473ZM101.932,23.644V17.733a17.656,17.656,0,0,0-1.013-5.911h66.033a17.764,17.764,0,0,1,16.72,11.822Z"
              fill="#d50037"
            />
            <path
              id="Path_1216"
              data-name="Path 1216"
              d="M228.733,204.038a5.918,5.918,0,0,1,5.911,5.911h11.822a17.761,17.761,0,0,0-11.822-16.716V180H222.822v13.233a17.731,17.731,0,0,0,5.911,34.449,5.911,5.911,0,1,1-5.911,5.911H211a17.761,17.761,0,0,0,11.822,16.716v13.233h11.822V250.309a17.731,17.731,0,0,0-5.911-34.449,5.911,5.911,0,0,1,0-11.822Z"
              transform="translate(-109.068 -109.068)"
              fill="#d50037"
            />
            <path
              id="Path_1217"
              data-name="Path 1217"
              d="M181,120h59.11v11.822H181Z"
              transform="translate(-90.89 -72.712)"
              fill="#d50037"
            />
          </g>
          <g id="Group_389" data-name="Group 389" transform="translate(-10.891 -18.299)">
            <path
              id="Polygon_2"
              data-name="Polygon 2"
              d="M49.2,13.547a9,9,0,0,1,15.6,0l41.433,71.963A9,9,0,0,1,98.433,99H15.567a9,9,0,0,1-7.8-13.491Z"
              transform="translate(499.891 605.299)"
              fill="#d50037"
            />
            <text
              id="_"
              data-name="!"
              transform="translate(547.891 692.299)"
              fill="#fff"
              fontSize="79"
              fontFamily="MyriadPro-BoldCond, Myriad Pro"
              fontWeight="700"
            >
              <tspan x="0" y="0">
                !
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    );
  };
}

export default PaymentFailure;
