import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import BackButton from "../../shared/BackButton";
class ClimateControl extends Component {
  onClickClimte = (isClimate) => {
    sessionStorage.setItem("climate_control", isClimate);
  };

  render() {
    return (
      <React.Fragment>
        <div className="wrapper wrapper-flex">
          <div className="container px-5">
            <div className="text-center">
              <h1 className="font-weight-bold">Do you need climate control?</h1>
            </div>
            <div className="row py-5">
              {/* <div className="col-md-8 mx-auto bg-white shadow py-5 rounded">
                    <p className="font-weight-bold m-0 text-center">
                        Do you need to protect your<br />belongings from extreme
                            </p>
                </div> */}
              <div className="col-md-6 mx-auto ">
                <div className="row">
                  <div className="col-12 mt-5 p-0 text-center">
                    <NavLink
                      onClick={() => this.onClickClimte(false)}
                      to={{
                        pathname: "/units",
                        backPath: "/climate-control",
                      }}
                      className="btn btn-dark btn-lg btn-block"
                    >
                      No
                    </NavLink>
                  </div>
                  <div className="col-12 mt-5 p-0 text-center">
                    <NavLink
                      onClick={() => this.onClickClimte(true)}
                      to={{
                        pathname: "/units",
                        backPath: "/climate-control",
                      }}
                      className="btn btn-pink btn-lg btn-block"
                    >
                      Yes
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BackButton backPath="/home" />
      </React.Fragment>
    );
  }
}

export default ClimateControl;
