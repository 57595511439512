/* eslint-disable no-undef */
import React, { Component } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import marked from "marked/lib/marked";
import { FcCalendar, FcHome, FcLock } from "react-icons/fc";
import QRCode from "react-qr-code";

import BackButton from "../../../shared/BackButton";
import {
  locationCode,
  reservationObject,
  moveInCostObject,
  getGateCode,
  contactObject,
  unitObject,
  taxSum,
  locationObject,
  getLeasePdfSignedData,
} from "../../../Utils/Common";
import UnitMapModal from "../../../shared/UnitMapModal";

import "react-loading-skeleton/dist/skeleton.css";
import { logApiFail } from "../../../Utils/rollbar";

class MoveInDone extends Component {
  state = {
    loadingLocationContents: true,
    locationContents: {},
    showUnitModal: false,
  };

  componentDidMount() {
    if (!sessionStorage.getItem("created_lease_number")) {
      this.props.history.push("/home");
    }
    this.loadMoveInResult();
    this.moveinCompletionNotice();
    this.getLocationContents();

    if (typeof KioApp !== "undefined") {
      KioApp.CloseKeyboard();
    }
  }

  moveinCompletionNotice() {
    const leaseNumber = sessionStorage.getItem("created_lease_number");
    const contact = contactObject();
    const moveInCost = moveInCostObject();
    const reservation = reservationObject();
    const unit = unitObject();
    const leasePdfSignedData = getLeasePdfSignedData();

    const params = {
      location_code: locationCode(),
      full_name: `${contact.first} ${contact.last}`,
      company_name: contact.company,
      cell_phone: contact.cell_phone,
      cell_email: contact.email,
      unit_number: reservation.unitNumber,
      unit_category: unit.unitTypeDetailedDescription,
      unit_rent: moveInCost.RentDetails.TotalRentAmount,
      lease_no: leaseNumber,
      discount: moveInCost.DiscountDetails.DiscountValue,
      other_fees_and_charges: moveInCost.FeeDetails.FeesAmount,
      access_code: getGateCode(),
      tax: taxSum(),
      lease_pdf_signed_data: leasePdfSignedData,
    };
    axios.post("/api/notices/movein_completion", params);
  }

  loadMoveInResult() {
    const leaseNumber = sessionStorage.getItem("created_lease_number");
    const reservation = reservationObject();
    const data = {
      location_code: locationCode(),
      tenant_id: reservation.tenantId,
      lease_no: leaseNumber,
    };

    axios
      .post("/api/ssm/GetTenantUnitLeaseDetails", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        const details = res.data.TenantUnitDetails;
        this.setState({
          unitNumber: details.UnitNumber[0],
          paidThroughDate: details.PaidThroughDate[0],
          gateCode: getGateCode(),
        });
      });
  }

  getLocationContents() {
    axios
      .get(`/api/location_contents/${locationCode()}`)
      .then((res) => {
        this.setState({ locationContents: res.data });
      })
      .catch((err) => {
        logApiFail(`/api/location_contents/${locationCode()}`, undefined, err);
        console.log("err", err);
      })
      .finally(() => {
        this.setState({ loadingLocationContents: false });
      });
  }

  render() {
    const mylocation = locationObject();
    const reservation = reservationObject();

    return (
      <React.Fragment>
        <div className="wrapper wrapper-flex">
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 text-center">
                <div className="text-center">
                  <h1 className="font-weight-bold">Congrats! You're moved in!</h1>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8">
                <div className="card mb-4">
                  <div className="card-body px-5">
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="text-black mb-0">
                          <FcHome /> Unit Number
                        </h2>
                      </div>
                      <div className="col-md-6 text-right">
                        <h2 className="text-danger mb-0">{this.state.unitNumber}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body px-5">
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="text-black mb-0">
                          <FcLock /> Gate Code
                        </h2>
                      </div>
                      <div className="col-md-6 text-right">
                        <h2 className="text-danger mb-0">{this.state.gateCode}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body px-5">
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="text-black mb-0">
                          <FcCalendar /> Paid Through
                        </h2>
                      </div>
                      <div className="col-md-6 text-right">
                        <h2 className="text-danger mb-0">{this.state.paidThroughDate}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                {mylocation.facility_map_enabled && (
                  <div className="text-center mt-4">
                    <button className="btn btn-lg btn-pink" onClick={() => this.setState({ showUnitModal: true })}>
                      Where's my unit?
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              {this.state.loadingLocationContents && (
                <div className="col-12 text-center my-4">
                  <Skeleton count={10} height={50} />
                </div>
              )}
              {!this.state.loadingLocationContents && (
                <div
                  className="col-12 text-center my-4"
                  dangerouslySetInnerHTML={{
                    __html: marked(this.state.locationContents?.kiosk_move_in_completion_screen || ""),
                  }}
                ></div>
              )}
              {mylocation.special_instruction_after_rental && (
                <div className="col-12 text-center my-4">
                  <h4>{mylocation.special_instruction_after_rental}</h4>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 text-center my-4">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "300px", width: "300px" }}
                  value={mylocation.feedback_link}
                  viewBox={`0 0 256 256`}
                />
                <h4 className="my-4">Leave us a review here!</h4>
              </div>
            </div>
          </div>
          <UnitMapModal
            unitNumbers={[{ unitNo: reservation.unitNumber }]}
            show={this.state.showUnitModal}
            onClose={() => this.setState({ showUnitModal: false })}
          />
        </div>
        <BackButton backPath="/home" backTitle="EXIT" />
      </React.Fragment>
    );
  }
}

export default MoveInDone;
