import axios from "axios";
import { getXmlAttribute } from "./Common";

export const getTenantDetailsByEmail = async (email) => {
  const data = {
    service_name: "GetTenantDetailsByEmail",
    xml_body: {
      strTenantEmailId: email,
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    return res.data.GetTenantDetailsByEmail && res.data.GetTenantDetailsByEmail.GetTenantDetailsByEmail;
  });
};

export const getUnitInfo = async (locationCode, unitNumber) => {
  const data = {
    service_name: "GetUnitsList",
    xml_body: {
      strLocationCode: locationCode,
      strSearchType: "4",
      strSearchString: unitNumber,
      strSortType: "",
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    if (res.data.UnitsList && res.data.UnitsList.Unit) {
      return res.data.UnitsList.Unit[0];
    } else {
      return {};
    }
  });
};

export const getTenantProfile = async (locationCode, tenantId) => {
  const data = {
    location_code: locationCode,
    tenant_id: tenantId,
  };

  return axios
    .post("/api/ssm/GetTenantInfo", JSON.stringify(data), {
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      const tenant = response.data.TenantDetails.Tenant;

      return {
        tenantId: getXmlAttribute(tenant, "TenantID"),
        first: getXmlAttribute(tenant, "FirstName"),
        middle: getXmlAttribute(tenant, "MiddleName"),
        last: getXmlAttribute(tenant, "LastName"),
        email: getXmlAttribute(tenant, "EMail"),
        cell_phone: getXmlAttribute(tenant, "CellPhone"),
        address: getXmlAttribute(tenant, "Address"),
        zip: getXmlAttribute(tenant, "ZipCode"),
        city: getXmlAttribute(tenant, "City"),
        state: getXmlAttribute(tenant, "State"),
        company: getXmlAttribute(tenant, "CompanyName"),
        home_phone: getXmlAttribute(tenant, "HomePhone"),
        company_address: getXmlAttribute(tenant, "CompanyAddress"),
        company_city: getXmlAttribute(tenant, "CompanyCity"),
        company_state: getXmlAttribute(tenant, "CompanyState"),
        company_zip_code: getXmlAttribute(tenant, "CompanyZipCode"),
        company_phone: getXmlAttribute(tenant, "CompanyPhone"),
        company_email: getXmlAttribute(tenant, "CompanyEMail"),
        date_of_birth: getXmlAttribute(tenant, "DateOfBirth"),
        active_military: getXmlAttribute(tenant, "ActiveMilitary") === "True",
      };
    });
};

export const getTenantUnitLeaseDetails = async (locationCode, tenantId, leaseNumber) => {
  const data = {
    service_name: "GetTenantUnitLeaseDetails",
    xml_body: {
      strLocationCode: locationCode,
      strTenantID: tenantId,
      strLeaseNumber: leaseNumber,
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    return res.data.TenantUnitDetails || {};
  });
};

export const getGetLocationDetails = async (locationCode) => {
  const data = {
    service_name: "GetLocationDetails",
    xml_body: {
      strLocationCode: locationCode,
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    if (res.data.LocationDetails) {
      return res.data.LocationDetails;
    } else {
      return {};
    }
  });
};

export const getTenantUnitList_V1 = async (locationCode, tenantId) => {
  const data = {
    service_name: "GetTenantUnitList_V1",
    xml_body: {
      strLocationCode: locationCode,
      strTenantId: tenantId,
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    if (res.data.TenantUnitList_V1 && res.data.TenantUnitList_V1.Unit1) {
      return Object.values(res.data.TenantUnitList_V1);
    } else {
      return [];
    }
  });
};

export const getInsuranceTerminology = async (locationCode) => {
  const data = {
    service_name: "GetInsuranceTerminology",
    xml_body: {
      strLocationCode: locationCode,
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    if (res.data.InsuranceTerminology) {
      return res.data.InsuranceTerminology && res.data.InsuranceTerminology.Terminology[0];
    } else {
      return {};
    }
  });
};

export const testXLinkEMVResult = {
  ACCOUNT: "476173XXXXXX0010",
  ALIAS: "8VEn36dGDq",
  APPROVALCODE: "040301",
  APPROVEDAMOUNT: "35.00",
  BATCHAMOUNT: "708337.50",
  BATCHNO: "000049",
  CARDBRAND: "VISA",
  CARDBRANDSHORT: "VS",
  CARDHOLDERNAME: "Test/Card 01              ",
  CARDTYPE: "Credit",
  DATE_TIME: "08-20-21 15:58:11",
  DUPLICATECARD: "True",
  EMVTRANSACTION: "True",
  ENTRYTYPE: "Chip",
  EXPMONTH: "12",
  EXPYEAR: "22",
  HOSTRESPONSECODE: "000",
  HOSTRESPONSEDESCRIPTION: "Approval",
  LANGUAGE: "ENGLISH",
  ORDERID: "145459647",
  RECEIPTTEXT: `
  ↵Terminal ID         XXXXXXXXXXXXXXX
  ↵Trans ID            000000001063
  ↵Order ID            145459647
  ↵Trans Type          Purchase
  ↵Date/Time           2021-08-20 15:58:11
  ↵Card Type           Visa
  ↵Card Number         XXXXXXXXXXXX0010
  ↵Entry Legend        CHIP READ
  ↵Entry Method        CONTACT
  ↵Approval Code       040301
  ↵AC                  A20F4EF002794101
  ↵ATC                 005C
  ↵AID                 A0000000031010
  ↵AID NAME            Visa Credit
  ↵TVR                 0280008000
  ↵TSI                 E800
  ↵Resp CD             00
  ↵TRN REF #           301232718910007
  ↵VAL CODE            CK97
  ↵
  ↵Total Amount        USD$35.00
  ↵
  ↵Description:_______________________
  ↵
  ↵          Approved - Thank You
  ↵
  ↵         No Signature Required
  ↵`,
  RESULT: "0",
  RESULTMSG: "Success",
  TRANSACTIONID: "000000001063",
};

export const GetTenantLastPaymentDetails = async (locationCode, tenantId) => {
  const data = {
    service_name: "GetTenantLastPaymentDetails",
    xml_body: {
      strLocationCode: locationCode,
      strTenantId: tenantId,
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    return res.data?.LastPaymentDetails ? res.data.LastPaymentDetails : {};
  });
};

export const addTenantNotes = async (locationCode, tenantId, noteText) => {
  const data = {
    service_name: "AddTenantNotes",
    xml_body: {
      strLocationCode: locationCode,
      TenantId: tenantId,
      NoteText: noteText,
    },
  };
  try {
    await axios.post("/api/ssm", data);
  } catch (error) {
    console.log(error);
  }
};

export const getLeaseAgreementDetails = (locationCode, leaseNumber) => {
  const data = {
    service_name: "GetLeaseAgreementDetails",
    xml_body: {
      strLocationCode: locationCode,
      strLeaseno: leaseNumber,
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    return res.data?.LeaseDetails ? res.data.LeaseDetails : {};
  });
}

export const getSignedLeaseHTML = (locationCode, leaseNumber) => {
  const data = {
    service_name: "GetSignedLeaseHTML",
    xml_body: {
      strLocationID: locationCode,
      strLeaseID: leaseNumber,
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    return {
      leaseNumber: leaseNumber,
      signed: !!res.data?.string?._
    }
  });
}

export const saveSignedLeaseAgrement = (locationCode, leaseNumber, signedInitial, leaseData ) => {
  const data = {
    service_name: "SaveSignedLeaseAgrement",
    xml_body: {
      FacilityId: locationCode,
      LeaseNo: leaseNumber,
      SignedInitial: signedInitial,
      StrIpAddress: '',
      LeaseDocument: leaseData,
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    return {
      leaseNumber: leaseNumber,
      signed: !!res.data?.string?._
    }
  });
}

export const checkSignedLease = (locationCode, leaseNumber) => {
  const data = {
    service_name: "CheckSignedLease",
    xml_body: {
      strLocationCode: locationCode,
      strLeaseNo: leaseNumber,
    },
  };

  return axios.post("/api/ssm", data).then((res) => {
    return {
      leaseNumber: leaseNumber,
      signed: res.data?.CheckSignedLease?.Successful[0] === "True"
    }
  });
}

export const sendLeaseAgreementV3 = async (locationCode, leaseNumber, signedInitial, signatureImage ) => {
  const data = {
    service_name: "SendLeaseAgreementWithAttachmentV3",
    xml_body: {
      strFacilityID: locationCode,
      strLeaseno: leaseNumber,
      Signature: signedInitial,
      base64Img: signatureImage,
      strSubject: 'test',
      strMessageBody: 'test',
      strFacilityName: 'S0010',
      strCompanyName: 'sparebox',
      strAutoCCChacked: '',
      strInsuranceSelected: '1',
      strIPAddress: '192.168.0.1',
    },
  };

  await axios.post("/api/ssm", data);
}
