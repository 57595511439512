import React from "react";

function AccessibleText(props) {
  return (
    <div className="accessible-text text-center">
      <h1>
        You may need to scroll the screen up to see all the buttons and fields. You can also use the tab button on the
        keyboard to move to the next entry field.
      </h1>
    </div>
  );
}

export default AccessibleText;
