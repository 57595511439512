import React from "react";
import { NavLink } from "react-router-dom";

import { locationObject } from "../../../Utils/Common";
import BackButton from "../../../shared/BackButton";
import withRetryPaymentCount from "../../HOC/withRetryPaymentCount";

const OnlinePaymentMethod = () => {
  const mylocation = locationObject();

  return (
    <div>
      <div className="wrapper wrapper-flex">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-7 mx-auto">
              <h1 className="font-weight-bold text-center">Select payment method</h1>
            </div>
          </div>
          <div className="row py-4">
            <div className="col-md-7 mx-auto">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-4 col-lg-2">
                      <img alt="Credit Card" src="asset/pay.png" height="45" width="auto" className="mb-2" />
                    </div>
                    <div className="col-8 col-lg-7 text-center">
                      <h3 className="mt-1 font-weight-bold">Credit Card</h3>
                    </div>
                    <div className="col-12 col-lg-3">
                      <NavLink
                        to={mylocation.allow_edge_express_in_kiosk ? "/online-payment-card" : "/online-payment-emv"}
                        className="btn btn-pink btn-block"
                      >
                        Select
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {mylocation.allow_ach && (
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-4 col-lg-2">
                        <img
                          alt="ACH Payment"
                          src="/asset/Page-1.png"
                          height="45"
                          width="auto"
                          className="mb-3 mb-lg-0"
                        />
                      </div>
                      <div className="col-8 col-lg-7 text-center">
                        <h3 className="mt-1 font-weight-bold">Bank Account</h3>
                      </div>
                      <div className="col-12 col-lg-3">
                        <NavLink to="/online-payment-ach" className="btn btn-pink btn-block">
                          Select
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BackButton backPath="/unit-lease-details" />
    </div>
  );
};

const PAGE_ID = "existing_payment_online_payment_method_retry_count";
export default withRetryPaymentCount(OnlinePaymentMethod, PAGE_ID, undefined, true);
