import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class BackButton extends Component {
  render() {
    return (
      <div className="bottom-nav w-25 back-button">
        <NavLink to={this.props.backPath || "/"}>
          <div className="card">
            <div className="card-body text-center">
              <img src="/asset/Group_165.png" width="30px" alt="Back" />
              <span className="btn text-black font-weight-bold pl-2 letter-spacing-2">
                {this.props.backTitle || "BACK"}
              </span>
            </div>
          </div>
        </NavLink>
      </div>
    );
  }
}
export default BackButton;
