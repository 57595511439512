/* eslint-disable no-undef */
import React, { Component } from "react";
import axios from "axios";
import { List } from "react-content-loader";

import { locationCode, pendingPaymentObject, tenantProfile } from "../../../Utils/Common";

import { logApiFail } from "../../../Utils/rollbar";
import withRetryPaymentCount from "../../HOC/withRetryPaymentCount";
class OnlinePaymentCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      loading: true,
    };
  }

  componentDidMount() {
    this.loadPaymentForm();

    if (typeof KioApp !== "undefined") {
      KioApp.StartKeyboard("", "", "number", false, "", "");
    }

    const listener = window.addEventListener("blur", () => {
      if (document.activeElement === document.querySelector("iframe")) {
        if (typeof KioApp !== "undefined") {
          KioApp.StartKeyboard("", "", "number", false, "", "");
        }
      }
      window.removeEventListener("blur", listener);
    });
  }

  componentWillUnmount() {
    if (typeof KioApp !== "undefined") {
      KioApp.CloseKeyboard();
    }
  }

  loadPaymentForm = async () => {
    const paymentObject = pendingPaymentObject();
    const profile = tenantProfile();
    if (!paymentObject) {
      alert("Failed to load payment data");
      this.props.history.push("/unit-lease-details");
      return;
    }

    const amount = paymentObject.rent;
    const payment = {
      location_code: locationCode(),
      total_amount: parseFloat(amount).toFixed(2),
      rent_amount: parseFloat(amount).toFixed(2),
      return_url: window.location.origin + "/online-confirm-payment",
      contact: profile,
    };
    axios
      .post("/api/paygateway/hostpay/transactions", JSON.stringify(payment), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        this.setState({
          url: response.data.paypageurl,
          loading: false,
        });
      })
      .catch((e) => {
        logApiFail("/paygateway/hostpay/transactions", payment, e);
      });
  };

  render() {
    return (
      <div className="wrapper">
        <div className="container">
          <div className="row py-5">
            <div className="col-sm-12 col-md-8">
              {this.state.loading ? (
                <List backgroundColor={"#ccc"} />
              ) : (
                <div>
                  <iframe title="Online Payment" className="paygateway-form" src={this.state.url} width="100%"></iframe>
                  <div className="credit-number-mask" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PAGE_ID = "exiting_payment_online_payment_card_retry_count";
const PAYMENT_METHOD_RETURN_URL = "/online-payment-method";
export default withRetryPaymentCount(OnlinePaymentCard, PAGE_ID, PAYMENT_METHOD_RETURN_URL, true);
