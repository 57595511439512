import React, { useState, useEffect } from "react";
import axios from "axios";
import marked from "marked/lib/marked";

function Banner() {
  const [bannerText, setBannerText] = useState("");

  useEffect(() => {
    axios.get("/api/settings?name=kiosk_banner").then((res) => {
      setBannerText(res.data?.value?.trim());
    });
  }, []);
  if (!bannerText || bannerText === "") {
    return false;
  }
  return (
    <h5
      className="p-3 bg-pink text-white mb-0 text-center border-bottom mp-0"
      dangerouslySetInnerHTML={{
        __html: marked(bannerText || ""),
      }}
    ></h5>
  );
}

export default Banner;
