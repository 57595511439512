import React from "react";
import { useInitPaymentAchData } from "../../../../hooks";
import { processACHPayments } from "../../../services/payment";
import { finishExistingTenantNote, getHandlerFocus, pendingPaymentObject, sessionObject } from "../../../Utils/Common";
import { isValidRoutingNumberAndAccountNumber } from "../../../Utils/paygateway";
import PaymentAchDetail from "../../../Utils/PaymentAchDetail";

function OnlinePaymentAch(props) {
  const [
    routingNumber,
    setRoutingNumber,
    accountNumber,
    setAccountNumber,
    message,
    setMessage,
    processing,
    setProcessing,
    dispatch,
    setInput,
  ] = useInitPaymentAchData();

  const loginObj = sessionObject();
  const paymentObject = pendingPaymentObject();
  const amount = parseFloat(paymentObject.rent);

  const handleFocus = getHandlerFocus(dispatch, setInput);

  if (!paymentObject) {
    window.location.href = "/unit-lease-details";
    return false;
  }

  const submitAchPayment = () => {
    const isValid = isValidRoutingNumberAndAccountNumber(routingNumber, accountNumber, setMessage);
    if (!isValid) return;

    setProcessing(true);
    processACHPayments({ routingNumber, accountNumber }).then((result) => {
      if (result && result.PaymentMessage[0] === "Successful") {
        // sendReceipt({ tenantId: getTenantId(), leaseNumber: paymentDetails.leaseNumber, amount: paymentDetails.rent, receiptNumber: "ACH_PAYMENT" })
        // clearPaymentData();
        finishExistingTenantNote(loginObj.id, "ACH");
        props.history.push("/unit-lease-details#paid");
      } else {
        setProcessing(false);
        setMessage("Failed to process ACH payment. Please contact customer support.");
      }
    });
  };

  return (
    <div className="wrapper">
      <div className="container">
        <div className="row py-5">
          <PaymentAchDetail
            containerClassName="col-md-7 mx-auto"
            accountNumber={accountNumber}
            handleFocus={handleFocus}
            message={message}
            processing={processing}
            routingNumber={routingNumber}
            setAccountNumber={setAccountNumber}
            setRoutingNumber={setRoutingNumber}
            submitAchPayment={submitAchPayment}
            totalAmount={amount}
          />
        </div>
      </div>
    </div>
  );
}

export default OnlinePaymentAch;
