import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";
import PublicRoute from "./PublicRoute";
// import PrivateRoute from './PrivateRoute';

// ==================== Public =======================

import Home from "../Pages/Home";
import Blank from "../Pages/Blank";
import Locations from "../Pages/Locations";
import Maintenance from "../Pages/Maintenance";

import ClimateControl from "../Pages/NewTenant/ClimateControl";
import Units from "../Pages/NewTenant/Units";
import Identity from "../Pages/NewTenant/Account/Identity";
import AccountInformation from "../Pages/NewTenant/Account/Information";
import LeaseSummary from "../Pages/NewTenant/Checkout/LeaseSummary";
import Vehicle from "../Pages/NewTenant/Insurance/Vehicle";
import VehicleInfo from "../Pages/NewTenant/Insurance/VehicleInfo";
import Insurance from "../Pages/NewTenant/Insurance/Insurance";
import ShieldBrochure from "../Pages/NewTenant/Insurance/ShieldBrochure";
import LeasePayment from "../Pages/NewTenant/Checkout/LeasePayment";
import ProcessPaymentEmv from "../Pages/NewTenant/Checkout/ProcessPaymentEmv";
import ProcessPaymentCard from "../Pages/NewTenant/Checkout/ProcessPaymentCard";
import ProcessPaymentAch from "../Pages/NewTenant/Checkout/ProcessPaymentAch";
import ConfirmPayment from "../Pages/NewTenant/Checkout/ConfirmPayment";
import MoveInDone from "../Pages/NewTenant/Checkout/MoveInDone";
import PaymentFailure from "../Pages/NewTenant/Checkout/PaymentFailure";
import TenantNotes from "../Pages/NewTenant/Insurance/TenantNotes";

import TenantLogin from "../Pages/Existing/Auth/TenantLogin";
import MyActivities from "../Pages/Existing/MyActivities";
import MyUnits from "../Pages/Existing/Options/MyUnits";
import UnitLeaseDetails from "../Pages/Existing/Options/UnitLeaseDetails";
import ScheduleMoveOut from "../Pages/Existing/Options/ScheduleMoveOut";

import OnlinePaymentMethod from "../Pages/Existing/Payment/OnlinePaymentMethod";
import OnlinePaymentEmv from "../Pages/Existing/Payment/OnlinePaymentEmv";
import OnlinePaymentAch from "../Pages/Existing/Payment/OnlinePaymentAch";
import OnlinePaymentCard from "../Pages/Existing/Payment/OnlinePaymentCard";
import OnlineConfirmPayment from "../Pages/Existing/Payment/OnlineConfirmPayment";

import FindReservation from "../Pages/Reservation/FindReservation";
import ConfirmReservation from "../Pages/Reservation/ConfirmReservation";
import ReservationNotFound from "../Pages/Reservation/ReservationNotFound";

import WesternExpoSelect from "../Pages/Extra/WesternExpoSelect";
import S0105_S0109 from "../Pages/Extra/S0105_S0109";
import CompleteLease from "../Pages/Existing/CompleteLease";

//Have
// import TakePicture from '../Pages/NewTenant/TakePicture';

function SwitchPages() {
  const [maintenanceSetting, setMaintenanceSetting] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    axios
      .get(`/api/settings?name=maintenance`)
      .then((res) => {
        if (res.status === 200) {
          setMaintenanceSetting(res.data);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, []);

  if (isProcessing) return null;

  if (maintenanceSetting?.value === "true") {
    return <Maintenance />;
  }

  return (
    <Switch>
      <Route exact path="/" component={Blank} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/locations" component={Locations} />

      {/* ================ New tenant pages ================ */}
      <PublicRoute path="/climate-control" component={ClimateControl} />
      <PublicRoute path="/units" component={Units} />
      <PublicRoute path="/identity" component={Identity} />
      <PublicRoute path="/account-information" component={AccountInformation} />
      <PublicRoute path="/vehicle" component={Vehicle} />
      <PublicRoute path="/vehicle-info" component={VehicleInfo} />
      <PublicRoute path="/insurance" component={Insurance} />
      <PublicRoute path="/shield-brochure" component={ShieldBrochure} />
      <PublicRoute path="/tenant-notes" component={TenantNotes} />
      <PublicRoute path="/lease-summary" component={LeaseSummary} />
      <PublicRoute path="/lease-payment" component={LeasePayment} />
      <PublicRoute path="/process-payment-emv" component={ProcessPaymentEmv} />
      <PublicRoute path="/process-payment-card" component={ProcessPaymentCard} />
      <PublicRoute path="/process-payment-ach" component={ProcessPaymentAch} />
      <PublicRoute path="/confirm-payment" component={ConfirmPayment} />
      <PublicRoute path="/movein-done" component={MoveInDone} />
      <PublicRoute path="/payment-failure" component={PaymentFailure} />

      {/* ================ Existing tenant pages ================ */}
      <PublicRoute path="/tenant-login" component={TenantLogin} />
      <PublicRoute path="/my-activities" component={MyActivities} />
      <PublicRoute path="/my-units" component={MyUnits} />
      <PublicRoute path="/unit-lease-details" component={UnitLeaseDetails} />
      <PublicRoute path="/schedule-move-out" component={ScheduleMoveOut} />
      <PublicRoute path="/online-payment-method" component={OnlinePaymentMethod} />
      <PublicRoute path="/online-payment-emv" component={OnlinePaymentEmv} />
      <PublicRoute path="/online-payment-ach" component={OnlinePaymentAch} />
      <PublicRoute path="/online-payment-card" component={OnlinePaymentCard} />
      <PublicRoute path="/online-confirm-payment" component={OnlineConfirmPayment} />

      {/* ================ Have Reservation pages ================ */}
      <PublicRoute path="/find-reservation" component={FindReservation} />
      <PublicRoute path="/confirm-reservation" component={ConfirmReservation} />
      <PublicRoute path="/reservation-not-found" component={ReservationNotFound} />

      {/** ======== Extra special pages ======== */}
      <PublicRoute path="/enhanced/select/s0004_s0005" component={WesternExpoSelect} />
      <PublicRoute path="/enhanced/select/s0105_s0109" component={S0105_S0109} />

      {/* Extra */}
      <Route exact path="/complete-lease" component={CompleteLease} />

      {/* ================ Unused pages ================ */}
      {/*
        <PublicRoute path="/insurance-plan" component={InsurancePlan} />
        <PublicRoute path="/take-picture" component={TakePicture} />
      
      */}
    </Switch>
  );
}

export default SwitchPages;
