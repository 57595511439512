import React from "react";
import { formatCurrency } from "../Utils/Common";

export const chooseDiscount = (ssmResult) => {
  if (ssmResult && ssmResult.Record) {
    const result = ssmResult.Record.find(
      (e) => e.DonotDisplay[0] === "False" && e.RevenueManagement[0] === "True" && e.AVAILABILITY[0] === "Y"
      // e.ApplyatMoveIn[0] === 'True'
    );

    if (result) {
      return {
        discountId: result.DISCOUNTID[0],
        discountName: result.DISCOUNTNAME[0],
        discountAmount: result.Value[0],
        discountType: result.Type[0],
      };
    }
  }

  return {
    discountId: 0,
    discountName: "",
    discountAmount: 0,
    discountType: 0,
  };
};

export const formatUnit = (unitData) => {
  if (unitData.discountId > 0) {
    if (unitData.discountType === "1") {
      return (
        <>
          <del>{formatCurrency(unitData.rent)}</del>
          {" / "}
          {formatCurrency((unitData.rent * (100 - unitData.discountAmount)) / 100.0)}
        </>
      );
    } else {
      return (
        <>
          <del>{formatCurrency(unitData.rent)}</del>
          {" / "}
          {formatCurrency(unitData.discountAmount)}
        </>
      );
    }
  } else {
    return <>{formatCurrency(unitData.rent)}</>;
  }
};

export const formatMoveInCost = (rentData) => {
  if (rentData.DiscountDetails.DiscountValue > 0) {
    return (
      <>
        <del>{formatCurrency(rentData.RentDetails.TotalRentAmount)}</del>
        {" / "}
        {formatCurrency(rentData.RentDetails.TotalRentAmount - rentData.DiscountDetails.DiscountValue)}
      </>
    );
  } else {
    return <>{formatCurrency(rentData.RentDetails.TotalRentAmount)}</>;
  }
};
