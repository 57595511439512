import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { locationObject, sessionObject } from "../../../Utils/Common";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUnitModal: false,
      loginObj: sessionObject(),
    };
  }

  render() {
    const mylocation = locationObject();
    return (
      <div className="row">
        <div className="col-12 mb-4">
          <div className="card profile-container rounded-0 border-0">
            <NavLink className="text-danger" to="/my-activities">
              <div className="card-body">
                My Profile
                <FaAngleRight className="ml-4 text-dark" />
              </div>
            </NavLink>
          </div>
        </div>
        <div className="col-12 mb-4">
          <div className="card profile-container rounded-0 border-0">
            <NavLink className="text-danger" to="/my-units">
              <div className="card-body">
                My Rentals/Reservations
                <FaAngleRight className="ml-4 text-dark" />
              </div>
            </NavLink>
          </div>
        </div>
        {mylocation.facility_map_enabled && (
          <div className="col-12 mb-4">
            <div className="card profile-container rounded-0 border-0">
              <a className="text-danger" href="/my-units?showMap=true">
                <div className="card-body">
                  Unit Map
                  <FaAngleRight className="ml-4 text-dark" />
                </div>
              </a>
            </div>
          </div>
        )}
        <div className="col-12 mb-4">
          <div className="card profile-container rounded-0 border-0">
            <NavLink className="text-danger" to="/tenant-login">
              <div className="card-body">
                Logout
                <FaAngleRight className="ml-4 text-dark" />
              </div>
            </NavLink>
          </div>
        </div>
        {/* <div className="col-12 mb-4">
                    <div className="card profile-container rounded-0 border-0">
                        <div className="card-body">
                            <NavLink className="text-danger" to="/recent-activity">Payment History</NavLink>
                            <FaAngleRight className="ml-4 text-dark" />
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-4">
                    <div className="card profile-container rounded-0 border-0">
                        <div className="card-body">
                            <NavLink className="text-danger" onClick={() => this.unitDetail(this.state.location)} to="#">Rent Additional Unit </NavLink>
                            <FaAngleRight className="ml-4 text-dark" />
                        </div>
                    </div>
                </div> */}
      </div>
    );
  }
}

export default ProfileMenu;
