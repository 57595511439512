import { actionTypes } from "./keyboard.reducer";

export const setInput = (name, value) => {
  return {
    type: actionTypes.SET,
    payload: { name, value },
  };
};

export const resetKeyboard = (inputNames) => {
  return {
    type: actionTypes.INIT,
    payload: inputNames,
  };
};
