import React, { Component, Fragment } from "react";
import axios from "axios";
import { setInsuranceType, setLocationObject } from "../Utils/Common";
import { getInsuranceTerminology } from "../Utils/data";

class Locations extends Component {
  state = {
    locations: [],
  };

  componentDidMount() {
    var url = new URL(window.location.href);
    const locationCodes = url.searchParams.get("code").split(" ");

    locationCodes.forEach((code) => {
      axios.get("/api/locations?location_code=" + code + "&role=kiosk").then((res) => {
        if (res.status === 200) {
          if (locationCodes.length > 1) {
            this.setState({ locations: this.state.locations.concat([res.data]) });
          } else {
            this.selectLocation(res.data);
          }
        }
      });
    });
  }

  selectLocation = async (location) => {
    setLocationObject(location);
    const terminology = await getInsuranceTerminology(location.location_code);
    setInsuranceType(terminology);
    this.props.history.push("/home");
  };

  render() {
    return (
      <Fragment>
        <div className="wrapper wrapper-flex py-5">
          <div className="container">
            <div className="loader-container">
              <img src="/SpareBox-Loading.svg" alt="Loading..." width="180px" />
              <p className="text-center h3 mt-5">Loading</p>
            </div>
            <div className="row">
              {this.state.locations.map((value, i) => (
                <div className="col-sm-6 mb-4" onClick={() => this.selectLocation(value)} key={i}>
                  <div className="list">
                    <div className="row my-5" key={i}>
                      <div className="col-md-6">
                        <img alt="Facility Location" src={value.photo} className="pointer" width="100%" height="100%" />
                      </div>
                      <div className="col-md-6">
                        <h2 className="pointer mb-2">{value.name}</h2>
                        <div>
                          <p className="font-weight-bold text-muted pointer mb-2 text-capitalize">
                            {`${value.address}, ${value.city}, ${value.state} ${value.zip}`}
                            <br />
                            <span className="text-danger ml-0">See Details</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Locations;
