import React, { Component, Fragment } from "react";
import axios from "axios";
import { BulletList } from "react-content-loader";
import {
  reservationObject,
  locationCode,
  setUnitObject,
  setContactObject,
  navigateToVehicleScreen,
  fetchUnitDataFromReservation,
} from "../../Utils/Common";
import BackButton from "../../shared/BackButton";
import { chooseDiscount, formatUnit } from "../../shared/Unit";

class ConfirmReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservation: reservationObject(),
      profile: false,
      unit: false,
    };
  }

  componentDidMount() {
    this.loadTenant();
    this.loadUnitType();
  }

  loadTenant = () => {
    const { reservation } = this.state;

    const data = {
      location_code: locationCode(),
      tenant_id: reservation.tenantId,
    };

    axios
      .post("/api/ssm/GetTenantInfo", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const profile = {
          tenantId: response.data.TenantDetails.Tenant[0].TenantID[0],
          first: response.data.TenantDetails.Tenant[0].FirstName[0].trim(),
          last: response.data.TenantDetails.Tenant[0].LastName[0].trim(),
          address: response.data.TenantDetails.Tenant[0].Address[0].trim(),
          city: response.data.TenantDetails.Tenant[0].City[0].trim(),
          state: response.data.TenantDetails.Tenant[0].State[0].trim(),
          zip: response.data.TenantDetails.Tenant[0].ZipCode[0].trim(),
          email: response.data.TenantDetails.Tenant[0].EMail[0].trim(),
          cell_phone: response.data.TenantDetails.Tenant[0].CellPhone[0].trim(),
          company: response.data.TenantDetails.Tenant[0].CompanyName[0].trim(),
        };
        setContactObject(profile);
        this.setState({ profile });
      });
  };

  // TODO: don't repeat yourself
  getDiscount(unitTypeId) {
    const data = {
      location_code: locationCode(),
      unit_type_id: unitTypeId,
    };

    return axios
      .post("/api/ssm/GetAllDiscounts", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => chooseDiscount(response.data.SSM));
  }

  loadUnitType = () => {
    const { reservation } = this.state;

    const data = {
      location_code: locationCode(),
      new_data: true, // TODO: load cache data if possible
    };
    axios
      .post("/api/ssm/GetLocationUnitTypes", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (response) => {
        const result = response.data.LocationUnitTypes.UnitType.find((e) => e.UnitTypeId[0] === reservation.unitType);
        var discount = await this.getDiscount(result.UnitTypeId[0]);
        const unit = {
          length: result.Length[0],
          breadth: result.Breadth[0],
          categoryName: result.CategoryName[0],
          rent: parseFloat(result.Rent[0]).toFixed(2),
          discount: result.Discount[0],
          unitTypeDescription: result.UnitTypeDescription[0],
          unitTypeDetailedDescription: result.UnitTypeDetailedDescription[0],
          unitTypeId: result.UnitTypeId[0],
          ...discount,
        };
        setUnitObject(unit);
        this.setState({ unit });
      });
  };

  nextPage = () => {
    const { profile } = this.state;

    if (
      profile.email.trim() === "" ||
      profile.cell_phone.trim() === "" ||
      profile.first.trim() === "" ||
      profile.address.trim() === "" ||
      profile.state.trim() === "" ||
      profile.city.trim() === "" ||
      profile.zip.trim() === ""
    ) {
      this.props.history.push("/account-information");
    } else {
      fetchUnitDataFromReservation();
      navigateToVehicleScreen(this.props);
    }
  };

  render() {
    const { unit, profile } = this.state;

    return (
      <Fragment>
        <div className="wrapper wrapper-flex">
          <div className="container">
            <div className="text-center">
              <h1 className="display-3 font-weight-bold">Reservation Details</h1>
            </div>
            <div className="row p-3">
              <div className="col-12 mx-auto">
                <div className="card">
                  <div className="card-header p-4">
                    {unit && (
                      <div className="row">
                        <div className="col-8">
                          <h3 className="m-0 font-weight-bold">
                            {`${unit.length}'x${unit.breadth}' | ${unit.categoryName}`}
                          </h3>
                        </div>
                        <div className="col-4">
                          <div className="m-0 text-right text-danger font-weight-bold">
                            <h5 className="m-0" style={{ fontSize: "22px" }}>
                              {formatUnit(unit)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="card-body">
                    <h4 className="text-secondary">CONTACT DETAILS</h4>
                    {profile ? (
                      <h5>
                        {profile.first} {profile.last}
                        <br />
                        {profile.address}
                        <br />
                        {profile.city}, {profile.state} {profile.zip}
                        <br />
                        {profile.cell_phone}
                        <br />
                        {profile.email}
                      </h5>
                    ) : (
                      <BulletList />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 text-right">
                <button onClick={this.nextPage} className="btn btn-danger btn-lg col-4">
                  Move In
                </button>
              </div>
            </div>
          </div>
          <BackButton backPath="/find-reservation" />
        </div>
      </Fragment>
    );
  }
}

export default ConfirmReservation;
