import React, { useState, useEffect, useRef, useCallback } from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import "./imagePreview.css";

const ImagePreview = ({ dataUri, isFullscreen }) => {
  const classNameFullscreen = isFullscreen ? "image-preview-fullscreen" : "";
  return (
    <div className={"image-preview " + classNameFullscreen}>
      <img src={dataUri} alt="Preview" />
    </div>
  );
};

ImagePreview.propTypes = {
  dataUri: PropTypes.string,
  isFullscreen: PropTypes.bool,
};

function TakePicture2({ onConfirm, isLoading = false }) {
  const intervalId = useRef(null);
  const timeoutId = useRef(null);

  const [cameraReady, setCameraReady] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const [dataUri, setDataUri] = useState("");
  const [time, setTime] = useState(3);

  useEffect(() => {
    if (cameraReady && time === 3) {
      clearTimeout(timeoutId.current);
      clearInterval(intervalId.current);
      startCountDown();
    }
  }, [cameraReady, time]);

  useEffect(() => {
    if (cameraError) {
      onConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraError]);

  const takePicture = useCallback(() => {
    if (!cameraReady) {
      return;
    }
    if (document.querySelector("#inner-circle")) {
      document.querySelector("#inner-circle").click();
    }
  }, [cameraReady]);

  const endCountDown = useCallback(() => {
    takePicture();
  }, [takePicture]);

  useEffect(() => {
    if (time === 0) {
      clearTimeout(timeoutId.current);
      clearInterval(intervalId.current);
      endCountDown();
    }
  }, [endCountDown, time]);

  const startCountDown = () => {
    setDataUri("");
    const id = setTimeout(() => {
      const id = setInterval(() => {
        setTime((preValue) => preValue - 1);
      }, 1000);

      intervalId.current = id;
    }, 1000);
    timeoutId.current = id;
  };

  function handleTakePhotoAnimationDone(dataUri) {
    setDataUri(dataUri);
  }

  const isFullscreen = false;
  return (
    <div className="col-12 d-flex flex-column justify-content-center">
      {cameraReady ? (
        <h2 className="text-center font-weight-bold">Please look at the camera</h2>
      ) : (
        <h2 className="text-center font-weight-bold d-flex camera-container justify-content-center align-items-center">
          <ClipLoader />
        </h2>
      )}
      {dataUri ? (
        <ImagePreview dataUri={dataUri} isFullscreen={isFullscreen} />
      ) : !cameraError ? (
        <div className="camera-container">
          <Camera
            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
            onCameraStart={() => setCameraReady(true)}
            onCameraStop={() => setCameraReady(false)}
            onCameraError={() => setCameraError(true)}
            isFullscreen={isFullscreen}
            isSilentMode={true}
          />
        </div>
      ) : null}
      {time !== 0 && cameraReady ? (
        <button className="btn btn-danger m-2">{time}</button>
      ) : (
        <div className="row my-2 justify-content-center">
          {cameraReady && (
            <button className="btn btn-danger mx-2" onClick={onConfirm} disabled={isLoading}>
              Confirm
            </button>
          )}
          {cameraReady && (
            <button className="btn btn-danger mx-2" onClick={() => setTime(3)}>
              Retake
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default TakePicture2;
