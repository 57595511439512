import React, { Component } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { locationObject, purgeNewTenantData, locationCode } from "../Utils/Common";
class Home extends Component {
  state = {
    hasClimateControl: true,
  };

  componentDidMount() {
    if (!locationObject()) {
      alert("Location is not selected!");
      this.props.history.push("/");
      return;
    }
    purgeNewTenantData();
    this.checkClimateControll();
  }

  checkClimateControll() {
    const data = {
      location_code: locationCode(),
      new_data: true, // TODO: load cache data if possible
    };
    axios
      .post("/api/ssm/GetLocationUnitTypes", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const climateControlled = response.data.LocationUnitTypes.UnitType.find(
          (value) => value.CategoryName[0].toLowerCase() === "climate controlled"
        );

        // If no climate conrolled unit is found, auto-redirect to units page
        if (!climateControlled) {
          this.setState({ hasClimateControl: false });
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="top-nav">
          <div className="w-100 head text-center py-3">
            <span className="">Welcome!</span>
          </div>
        </div>
        <div className="wrapper wrapper-flex">
          <div className="container">
            <div className="text-center">
              <img src="/asset/logo.png" alt="" width="310px" />
            </div>
            <div className="row py-5">
              <div className="col-12">
                <div className="card">
                  <div className="card-body py-6">
                    <div className="row">
                      <div className="col-2 col-lg-2 mt-2 text-center">
                        <img src="/asset/Union_2.png" className="mt-4" alt="" width="60" />
                      </div>
                      <div className="col-10 col-lg-10 welcome-card">
                        <h2 className="text-black font-weight-bold mb-2">I Have a Reservation</h2>
                        <h4 className="text-black font-weight-bold mb-4 ">Finish Your Rental and Move In</h4>
                        <NavLink className="btn btn-pink  btn-lg" to="/find-reservation">
                          Look Up Reservation
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5">
                <div className="card">
                  <div className="card-body py-6">
                    <div className="row">
                      <div className="col-2 col-lg-2 mt-2 text-center">
                        <img src="/asset/Group_161.png" className="mt-4" alt="" width="60" />
                      </div>
                      <div className="col-10 col-lg-10 welcome-card">
                        <h2 className="text-black font-weight-bold mb-2">I'm a New Customer</h2>
                        <h4 className="text-black font-weight-bold mb-4 ">Browse Availability and Rent Now</h4>
                        <NavLink
                          to={{
                            pathname: this.state.hasClimateControl ? "/climate-control" : "/units",
                            backPath: "/home",
                          }}
                          className="btn btn-pink btn-lg"
                        >
                          Find a Unit
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5">
                <div className="card">
                  <div className="card-body py-6">
                    <div className="row">
                      <div className="col-2 col-lg-2 mt-2 text-center">
                        <img src="/asset/Union_2.png" className="mt-4" alt="" width="60" />
                      </div>
                      <div className="col-10 col-lg-10 welcome-card">
                        <h2 className="text-black font-weight-bold mb-2">I'm an Existing Tenant</h2>
                        <h4 className="text-black font-weight-bold mb-4">Manage Account, Get Gate Code and Review Lease</h4>
                        <NavLink className="btn btn-pink  btn-lg" to="/tenant-login">
                          Welcome Back
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
