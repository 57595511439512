import React from "react";
import axios from "axios";
import moment from "moment";

import {
  locationCode,
  cardType,
  getPostResult,
  autopayBit,
  locationObject,
  sessionObject,
  pendingPaymentObject,
  getTenantLease,
  tenantProfile,
} from "../../../Utils/Common";
import {
  addTenantNotes,
  getTenantUnitLeaseDetails,
  // testXLinkEMVResult as _testXLinkEMVResult
} from "../../../Utils/data";
import { isValidPayment, makePayment, confirmAlertShow } from "../../../Utils/paygateway";
import ProcessPaymentRetry from "../../../Utils/ProcessPaymentRetry";
import ConfirmPaymentPlaceholder from "../../../shared/ConfirmPaymentPlaceholder";

class OnlineConfirmPayment extends ProcessPaymentRetry {
  componentDidMount() {
    const result = getPostResult();
    const isValid = isValidPayment(
      result,
      () => {
        this.props.history.push("/online-payment-method?retry=true");
      },
      () => {
        this.props.history.push("/online-payment-method?retry=true");
      }
    );

    if (!isValid) {
      const loginObj = sessionObject();
      addTenantNotes(locationCode(), loginObj.id, "Kiosk Payment Failure: " + result.responseDescription);
      return;
    }

    const requestBody = {
      location_code: locationCode(),
      orderId: result.orderId,
    };

    makePayment(
      requestBody,
      (err) => {
        window.location.href = "/online-payment-method?retry=true";
      },
      (responseData) => {
        this.processPayment(responseData);
      }
    );
  }

  processPayment = (transactionData) => {
    const loginObj = sessionObject();
    const mylocation = locationObject();
    const paymentObject = pendingPaymentObject();
    const profile = tenantProfile();

    const requestBody = {
      location_code: locationCode(),
      tenant_id: loginObj.id,
      unit_id: paymentObject.unitID,
      amount: parseFloat(paymentObject.rent),
      pay_periods: parseInt(paymentObject.prePayPeriods),
      payment_method: "CC",
      cc_type: cardType(transactionData.CARDBRAND[0]),
      cc_no: transactionData.MASKEDCARDNUMBER[0],
      cc_expiration_date: `${transactionData.EXPMONTH[0]}/${2000 + transactionData.EXPYEAR[0] * 1}`,
      cc_address: profile.address,
      cc_postal_code: profile.zip,
      cc_name: `${profile.firstName} ${profile.lastName}`,
      transaction_id: transactionData.TRANSACTIONID[0],
      cc_response_code: transactionData.RESPONSECODE[0],
      autopay_bit: autopayBit(),
      order_id: transactionData.ORDERID[0],
      token: transactionData.ALIAS[0],
      auction_proceeds: 0,
    };

    this.handleProcessPaymentAPI(
      requestBody,
      async () => {
        // success case
        const lease = getTenantLease();
        const tenantInfo = sessionObject();
        getTenantUnitLeaseDetails(locationCode(), loginObj.id, paymentObject.leaseNumber).then((newLease) => {
          const params = {
            full_name: `${tenantInfo.first}, ${tenantInfo.last}`,
            unit_number: lease.unitNumber,
            receipt_number: transactionData.ORDERID[0],
            payment_date: moment().format("L"),
            amount_due_before_payment: lease.currentBalanceDue,
            account_balance_if_negative_after_payment: newLease.CurrentBalanceDue[0],
            amount_paid: transactionData.APPROVEDAMOUNT[0],
            paid_thru_date: newLease.PaidThroughDate[0],
            location_phone: mylocation.phone,
            email: lease.tenantEMail,
          };
          axios.post("/api/notices/receipt", params);
        });
        this.props.history.push("/unit-lease-details#paid");
      },
      (result) => {
        // this will handle like incorrect digit check CC
        confirmAlertShow("", result);
      },
      () => {},
      true
    );
  };

  render() {
    return <ConfirmPaymentPlaceholder />;
  }
}

export default OnlineConfirmPayment;
