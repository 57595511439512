import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Keyboard from "react-simple-keyboard";
import { setInput } from "../../redux/Keyboard/keyboard.action";

function ScreenKeyboard(props) {
  const [layout, setLayout] = useState("default");
  const [hidden, setHidden] = useState(false);

  const keyboard = useRef();
  const { inputNames, currentInput, currentValue } = useSelector((state) => state.keyboard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (keyboard.current) {
      keyboard.current.setInput(currentValue);
      keyboard.current.setCaretPosition(currentValue.length);
      setHidden(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput]);

  const onChange = (input) => {
    let newValue = input;
    const currentDom = document.getElementsByName(currentInput)[0];

    if (currentDom && currentDom.maxLength > 0) {
      newValue = newValue.substring(0, currentDom.maxLength);
      keyboard.current.setInput(newValue);
    }

    dispatch(setInput(currentInput, newValue.trim()));
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
    }
    if (button === "{hide}") {
      setHidden(true);
      dispatch(setInput("", ""));
    }
    if (button === "{tab}") {
      const nextInput = inputNames[inputNames.indexOf(currentInput) + 1];

      const nextDom = document.getElementsByName(nextInput)[0];

      if (nextDom) {
        dispatch(setInput(nextInput, nextDom.value));
        nextDom.focus();
        keyboard.current.setCaretPosition(nextDom.value.length);
      }
      return;
    }
    if (button === "{enter}") {
      const currentDom = document.getElementsByName(currentInput)[0];
      if (currentDom) {
        const formDom = currentDom.closest("form");

        formDom.dispatchEvent(new Event("submit"));
      }
    }
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  if (!props.visible && (inputNames.length === 0 || currentInput === "" || currentInput === "submit" || hidden)) {
    return false;
  }

  return (
    <Keyboard
      layout={keyboardLayout}
      mergeDisplay={true}
      display={{ "{hide}": "hide" }}
      baseClass="keyboard-wrapper"
      layoutName={layout}
      onChange={onChange}
      onKeyPress={onKeyPress}
      inputName="first"
      tabCharOnTab={false}
      keyboardRef={(r) => (keyboard.current = r)}
    />
  );
}

const keyboardLayout = {
  default: [
    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} q w e r t y u i o p [ ] \\",
    "{lock} a s d f g h j k l ; ' {enter}",
    "{shift} z x c v b n m , . / {shift}",
    ".com @ {space} {hide}",
  ],
  shift: [
    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{tab} Q W E R T Y U I O P { } |",
    '{lock} A S D F G H J K L : " {enter}',
    "{shift} Z X C V B N M < > ? {shift}",
    ".com @ {space} {hide}",
  ],
};

export default ScreenKeyboard;
