import React from "react";

function Maintenance() {
  return (
    <div className="wrapper wrapper-flex">
      <div className="container my-4">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div>
            <img src="/asset/SpareBox_Maintenance.png" alt="" style={{ minWidth: 330, maxWidth: 400, width: "100%" }} />
          </div>
          <p className="text-center mt-3">
            Our Kiosk and Website are currently unavailable, call us at 877-777-7273 for assistance.
            <br /> We apologize for the inconvenience.
            <br /> SpareBox Storage
          </p>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
