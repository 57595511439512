import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { locationCode, setReservationObject } from "../../Utils/Common";
import BackButton from "../../shared/BackButton";
import { setInput, resetKeyboard } from "../../../redux/Keyboard/keyboard.action";
import { logApiFail } from "../../Utils/rollbar";

function FindReservation(props) {
  const [lastName, setLastName] = useState("");
  const [phoneLastFour, setPhoneLastFour] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const { currentInput, currentValue } = useSelector((state) => state.keyboard);

  useEffect(() => {
    dispatch(resetKeyboard(["last_name", "phone_last_four", "submit"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentInput === "last_name") setLastName(currentValue);
    if (currentInput === "phone_last_four") setPhoneLastFour(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput, currentValue]);

  const handleFocus = (inputName, e) => {
    dispatch(setInput(inputName, e.target.value));
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      location_code: locationCode(),
      last_name: lastName.toLowerCase(),
      phone_last_four: phoneLastFour,
    };

    axios
      .post("/api/ssm/GetActiveReservation", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const reservation = response.data.reservation;
        if (reservation) {
          const reservationObject = {
            tenantId: reservation.TenantID[0],
            unitType: reservation.UnitType[0],
            unitCode: reservation.UnitCode[0],
            unitNumber: reservation.UnitNumber[0],
            rent_amount: reservation.RentAmount[0],
            reservationCode: reservation.ReservationCode[0],
          };

          setReservationObject(reservationObject);
          props.history.push("/confirm-reservation");
        } else {
          props.history.push("/reservation-not-found");
        }
      })
      .catch((error) => {
        logApiFail("/api/ssm/GetActiveReservation", data, error);
        setError("Something went wrong! Please enter correct information.");
      });
  };

  return (
    <div className="wrapper wrapper-flex">
      <div className="container">
        <div className="text-center">
          <h1 className="font-weight-bold">Find My Reservation</h1>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className="row py-2">
            <div className="col-md-8 mx-auto">
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control form-control-lg ${currentInput === "last_name" ? "focus" : ""}`}
                  placeholder="Last Name"
                  autoComplete="off"
                  name="last_name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onFocus={(e) => handleFocus("last_name", e)}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  maxLength="4"
                  className={`form-control form-control-lg ${currentInput === "phone_last_four" ? "focus" : ""}`}
                  placeholder="Last 4 digits of your phone number"
                  autoComplete="off"
                  name="phone_last_four"
                  value={phoneLastFour}
                  onChange={(e) => setPhoneLastFour(e.target.value)}
                  onFocus={(e) => handleFocus("phone_last_four", e)}
                />
              </div>
            </div>
            <div className="col-12 text-center">
              <p className="text-danger">{error}</p>
            </div>
            <div className="col-12 mt-5 text-center">
              <button name="submit" type="sumbit" className="btn btn-pink btn-lg col-4">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <BackButton backPath="/home" />
    </div>
  );
}

export default FindReservation;
