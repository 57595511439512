import React, { Component } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { locationCode, tenantId } from "../../../Utils/Common";
import XMLParser from "react-xml-parser";
import ProfileMenu from "../Profile/ProfileMenu";
import UnitMapModal from "../../../shared/UnitMapModal";

class MyUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      showUnitModal: props.location.search.includes("showMap"),
    };
  }

  componentDidMount() {
    const data = {
      location_code: locationCode(),
      tenant_id: tenantId(),
    };
    axios
      .post("/api/ssm/GetTenantUnitList_V1", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const data = new XMLParser().parseFromString(response.data);
        const unitList = data.getElementsByTagName("TenantUnitList_V1")[0].children;

        if (unitList.length > 0) {
          const units = unitList.map((data) => ({
            unitNo: data.children[0].value,
            leaseNumber: data.children[1].value,
          }));
          this.setState({ units });
          window.scrollTo(0, 0);
        }
      });
  }

  onClickUnit = (unit) => {
    sessionStorage.setItem("selected_lease_number", unit.leaseNumber);
  };

  render() {
    return (
      <div
        className="wrapper wrapper-flex py-5"
        // style={{ background: 'url("/asset/noise-pattern-with-subtle-cross-lines.png") center center' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-4 mt-4 order-2 order-lg-1">
              <ProfileMenu />
            </div>
            <div className="col-lg-8 mb-4 order-1 order-lg-2">
              <div className="row" id="scroll">
                <div className="col-md-12 info-text">
                  <h4>My Units</h4>
                  <hr />
                </div>

                {this.state.units.map((value, i) => (
                  <div className="col-md-4 mb-4 stretch-card" key={i}>
                    <NavLink className="card" onClick={() => this.onClickUnit(value)} to="/unit-lease-details">
                      <div className="card-body text-center">
                        <div style={{ fontSize: "60px" }}>
                          <img alt="My Unit" src="/asset/unit.svg" width="72" />
                        </div>
                        <h6 className="text-darker">Unit No: {value.unitNo}</h6>
                        <h6 className="text-darker">Lease No: {value.leaseNumber}</h6>
                        {/* <span>View Information</span> */}
                      </div>
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <UnitMapModal
          unitNumbers={this.state.units}
          show={this.state.showUnitModal}
          onClose={() => this.setState({ showUnitModal: false })}
        />
      </div>
    );
  }
}

export default MyUnits;
