import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetKeyboard, setInput } from "./redux/Keyboard/keyboard.action";

export const useInitPaymentAchData = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [processing, setProcessing] = useState(false);
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const { currentInput, currentValue } = useSelector((state) => state.keyboard);

  useEffect(() => {
    dispatch(resetKeyboard(["routing_number", "account_number", "submit"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentInput === "routing_number") setRoutingNumber(currentValue);
    if (currentInput === "account_number") setAccountNumber(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput, currentValue]);

  return [
    routingNumber,
    setRoutingNumber,
    accountNumber,
    setAccountNumber,
    message,
    setMessage,
    processing,
    setProcessing,
    dispatch,
    setInput,
  ];
};
