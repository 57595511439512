import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import SignaturePad from "react-signature-canvas";
import { BulletList } from "react-content-loader";

import { getTenantLeaseStatus, setTenantLeaseStatus } from "../../Utils/Common";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { locationCode } from "../../Utils/Common";
import { getLeaseAgreementDetails, getTenantUnitList_V1, sendLeaseAgreementV3 } from "../../Utils/data";
import { saveTenantLeaseCompleted } from "../../services/lease"

const CompleteLease = (props) => {
  const [leaseList, setLeaseList] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [completedModal, setCompletedModal] = useState(false);
  const [leaseReady, setLeaseReady] = useState(false);

  const [pdfUrl, setPdfUrl] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const leaseData = useRef(null);
  const signatureRef = useRef(null);

  const pdfFileName = "lease.pdf";

  useEffect(() => {
    loadLeaseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadLeaseData = async () => {
    const status = getTenantLeaseStatus()
    if (!status || !status.required) {
      window.location.href = "/";
      return;
    }

    if (!locationCode()) {
      alert("Failed to load location data, please try again later.");
      window.location.href = "/";
      return;
    }
    const unitList = await getTenantUnitList_V1(locationCode(), status.tenant_id);

    if (!unitList || unitList.length === 0) {
      alert("No lease found, please try again later.");
      window.location.href = "/";
      return;
    }
    setLeaseList(unitList);

    const firstLeaseNumber = unitList[0][0].LeaseNumber[0];
    leaseData.current = await getLeaseAgreementDetails(locationCode(), firstLeaseNumber);

    if (!leaseData.current.TenantLeaseDetails[0]) {
      alert("Invalid lease number, please try again later.");
      window.location.href = "/";
    }
    generateLeasePDF();
  };

  const generateLeasePDF = (signed = false, signatureData = null) => {
    const tenant = leaseData.current.TenantLeaseDetails[0];
    const payment = leaseData.current.PaymentDetails[0];
    const facility = leaseData.current.StorageFacilityDetails[0];
    const timestamp = moment().format("L HH:mm:ss");

    const dueDate = payment.PaidThrDate[0];
    const nextBillingDate = moment(new Date(dueDate)).add(1, "day").format("L");

    // const signedInitial = tenant.TenantFname[0].charAt(0) + tenant.TenantLname[0].charAt(0);

    const data = {
      name: tenant.TenantFname[0] + " " + tenant.TenantLname[0],
      address: tenant.Address[0],
      cell_phone: tenant.CellPhone[0],
      city: tenant.TenantCity[0],
      state: tenant.TenantState[0],
      zip: tenant.TenantZip[0],
      email: tenant.EmailId[0],
      company: tenant.TenantCompany[0],
      move_in_date: tenant.MoveInDate[0],
      unit_number: tenant.UnitNo[0],
      length: tenant.Length[0],
      breadth: tenant.Width[0],
      gate_code: tenant.GateCode[0],
      monthly_rent: tenant.Rent[0],
      admin_fee: tenant.AdminFee[0],
      coverage: tenant.CoverageAmount[0],
      insurance_amount: payment.InsAmount[0],
      tax: payment.RentalTax[0],
      discount: payment.DiscountDue[0],
      total_rent: payment.NetRent[0],
      due_on: payment.PaidThrDate[0],
      next_billing_date: nextBillingDate,
      facility_name: facility.StorageFacilityName[0],
      facility_address: facility.StorageFacilityAddress[0],
      facility_phone: facility.StorageFacilityPhone[0],
      facility_email: facility.StorageFacilityEmail[0].trim(),
      facility_city: facility.StorageFacilityCity[0],
      facility_zip: facility.StorageFacilityZip[0],
      facility_state: facility.StorageFacilityState[0],
      current_date: timestamp,
      accepted_timestamp: signed
        ? `Accepted by Customer ${tenant.TenantFname[0] + " " + tenant.TenantLname[0]} at ${timestamp}`
        : "",
      signature_data: signatureData,
      format: "file",
      location_code: locationCode(),
    };

    axios
      .post("/api/ssm/lease-pdf", data, {
        headers: { "Content-Type": "application/json" },
        responseType: "blob",
      })
      .then((response) => {
        var file = new Blob([response.data], {
          type: "application/pdf;base64",
        });
        const blobUrl = URL.createObjectURL(file);
        setPdfUrl(blobUrl + "#toolbar=0");
        setLeaseReady(true);
      });
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    setSignatureModal(false);
    const tenant = leaseData.current.TenantLeaseDetails[0];
    const signedInitial = tenant.TenantFname[0].charAt(0) + tenant.TenantLname[0].charAt(0);
  
    const signatureData = signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png")
      .replace(/^data:image\/?[A-z]*;base64,/, "");

    await Promise.all(leaseList.map(unit => sendLeaseAgreementV3(locationCode(), unit[0].LeaseNumber[0], signedInitial, signatureData)))

    await saveTenantLeaseCompleted();
    setCompletedModal(true);

    setTimeout(() => {
      setCompletedModal(false);
      navigateBack()
    }, 500);
  }

  const navigateBack = () => {
    const status = getTenantLeaseStatus();

    const nextPath = status.nextPath;

    setTenantLeaseStatus(null);

    window.location.replace(nextPath);
  }

  return (
    <div className="wrapper wrapper-flex">
      <div className="container py-5">
        <div className="row mt-5">
          <div className="col-12 col-lg-8 mx-auto">
            <div className="card shadow border-0">
              <div className="card-body">
                <h4 className="text-danger font-weight-bold">Updated Lease Terms</h4>
                <p>
                  We've updated the terms of our lease. Click on "See lease details" to review the terms. The primary update is a provision regarding lithium batteries. To continue to your account, please read the terms below and click the Accept button. <br/> <b> This action must be completed by the primary account holder.</b>
                </p>
                <div>
                  <h5 className="text-black font-weight-bold">Summary of Terms</h5>
                  <ul className="text-black font-weight-bold">
                    <li>Month-to-month term, notice to vacate must be received before your next billing cycle</li>
                    <li>Rental rate and other lease terms subject to change upon notice</li>
                    <li>You need to lock and insure your property as Lessor is not liable</li>
                    <li>Failure to pay rent timely could result in the auction of your property</li>
                    <li>Please note if you are a member of the military</li>
                    <li>Your lease contains rules and regulations</li>
                    <li>Hours of operation are posted on website</li>
                    <li>Monthly invoice will be sent upon request</li>
                  </ul>
                  <p className="text-black font-weight-bold">
                    Please read your entire lease to know all of your terms. By clicking Accept below, you acknowledge
                    you have read, understand, and agree to the full terms of the lease.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-12 col-lg-3">
                {leaseReady ? (
                  <div href="#" onClick={() => setPreviewModal(true)} className="btn btn-link">
                    See lease details
                  </div>
                ) : (
                  <SkeletonTheme color="#aaa" highlightColor="#eee">
                    <Skeleton count={1} height={15} />
                  </SkeletonTheme>
                )}
              </div>
              <div className="col-sm-12 col-lg-3">
                {leaseReady ? (
                  <a
                    href={pdfUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-link"
                    download={pdfFileName}
                  >
                    Download
                  </a>
                ) : (
                  <SkeletonTheme color="#aaa" highlightColor="#eee">
                    <Skeleton count={1} height={15} />
                  </SkeletonTheme>
                )}
              </div>
              <div className="col-sm-12 col-lg-6 text-right">
                {submitting ? (
                  <h6 className="p-2 text-pink">Submitting lease document...</h6>
                ) : (
                  <button className="btn btn-pink btn-lg letter-spacing-2" onClick={() => setSignatureModal(true)}>
                    Accept
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={signatureModal} onRequestClose={() => setPreviewModal(false)} style={modalStyles}>
        <div className="modal-header">
          <h4>Draw your signature</h4>
          <div onClick={() => setSignatureModal(false)} className="modal-close-btn">
            <MdClose size="2em" />
          </div>
        </div>
        <div className="modal-content">
          <SignaturePad
            ref={signatureRef}
            canvasProps={{
              width: 600,
              height: 200,
              className: "sig-canvas",
            }}
          />
        </div>
        <div className="modal-footer">
          <button onClick={() => signatureRef.current.clear()} className="btn btn-secondary">
            Reset
          </button>
          <button onClick={handleSubmit} disabled={submitting} className="btn btn-pink">
            {submitting ? "Submitting..." : "Submit" }
          </button>
        </div>
      </Modal>
      <Modal isOpen={previewModal} onRequestClose={() => setPreviewModal(false)} style={modalStyles}>
        <div onClick={() => setPreviewModal(false)} className="modal-close-btn">
          <MdClose size="3em" />
        </div>
        <div className="modal-content pdf-modal-content" style={{ width: "calc(100vw - 64px)", height: "85vh" }}>
          {pdfUrl ? (
            <iframe title="Lease PDF" type="application/pdf" src={pdfUrl} width="100%" height="100%" />
          ) : (
            <BulletList />
          )}
        </div>
      </Modal>
      <Modal isOpen={completedModal} style={modalStyles}>
        <div className="text-center p-4">
          <h4>Success! Thank you for submitting your lease signature.</h4>
        </div>
      </Modal>
    </div>
  );
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default CompleteLease;
