const INITIAL_STATE = {
  first: "",
  last: "",
  email: "",
  cell_phone: "",
  company: "",
  address: "",
  state: "",
  city: "",
  zip: "",
};

export const actionTypes = {
  SET: "ACCOUNT/SET",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default reducer;
