import axios from "axios";
import { locationObject } from "./Common";

// Sends a request to RcmService
// paramString: the Xl2 paramater string specifying the transaction
// url: to location of the RCM service
// port: the port the RCM service is listening on
// rcmMethod: the RCM service method to call.  (Typically "Initialize")
// handleResult: a callback function that takes the result from RCM as a paramater
export const sendRequestToRcm = (paramString, url, port, rcmMethod) => {
  return axios({
    method: "GET",
    url: url + ":" + port + "/RcmService.svc/" + rcmMethod, // https://localsystem.paygateway.com:21113/RcmService.svc/Initialize
    params: {
      xl2Parameters: paramString,
    },
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).then((res) => {
    if (res.status === 200 && res.data.IsSuccessful) {
      const rcmResult = JSON.parse(res.data.RcmResponse);
      return rcmResult;
    } else {
      return { error: "A server-side error has occurred from RCM." };
    }
  });
};

export const waitCreditSaleFromRcm = (amount, orderId) => {
  const location = locationObject();

  const paramString = `<XLINKEMVREQUEST>
      <TRANSACTIONTYPE>CREDITSALE</TRANSACTIONTYPE>
      <XWEBAUTHKEY>${location.kiosk_xwebauthkey}</XWEBAUTHKEY>
      <XWEBTERMINALID>${location.kiosk_xwebterminalid}</XWEBTERMINALID>
      <XWEBID>${location.kiosk_xwebid}</XWEBID>
      <ALLOWDUPLICATES />
      <ORDERID>${orderId}</ORDERID>
      <AMOUNT>${amount}</AMOUNT>
      </XLINKEMVREQUEST>
    `;

  const url = "https://localsystem.paygateway.com";
  const port = 21113;
  const rcmMethod = "Initialize";

  return sendRequestToRcm(paramString, url, port, rcmMethod);
};
