import React from "react";
import { Link } from "react-router-dom";
import {
  amountDue,
  taxSum,
  moveInCostObject,
  locationObject,
  getInsuranceType,
  formatCurrency,
} from "../../../Utils/Common";
import BackButton from "../../../shared/BackButton";
import withRetryPaymentCount from "../../HOC/withRetryPaymentCount";

const LeasePaymentForm = (props) => {
  const mylocation = locationObject();
  const moveInCost = moveInCostObject();

  return (
    <React.Fragment>
      <div className="wrapper wrapper-flex">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="text-center">
                <h1 className="font-weight-bold">Lease Payment</h1>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-8 col-md-8 mx-auto mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-8">
                      <span className="font-weight-bold text-dark">Amount Due</span>
                    </div>
                    <div className="col-4 text-right">
                      <span className="text-danger font-weight-bold">{formatCurrency(amountDue())}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <span className="text-dark">Rent Amount</span>
                    </div>
                    <div className="col-4 text-right">
                      <span className="text-dark">
                        {formatCurrency(moveInCost.RentDetails.TotalRentAmount)}
                      </span>
                    </div>
                  </div>
                  {moveInCost.InsuranceDetails.InsuranceAmount > 0 && (
                    <div className="row">
                      <div className="col-8">
                        <span className="text-dark">{getInsuranceType()} Amount</span>
                      </div>
                      <div className="col-4 text-right">
                        <span className="text-dark">
                          {formatCurrency(
                            moveInCost.InsuranceDetails.InsuranceAmount
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {moveInCost.FeeDetails.FeesAmount > 0 && (
                    <div className="row">
                      <div className="col-8">
                        <span className="text-dark">
                          Fee: {moveInCost.FeeDetails.Description}
                        </span>
                      </div>
                      <div className="col-4 text-right">
                        <span className="text-dark">
                          {formatCurrency(moveInCost.FeeDetails.FeesAmount)}
                        </span>
                      </div>
                    </div>
                  )}
                  {moveInCost.DiscountDetails.DiscountValue > 0 && (
                    <div className="row">
                      <div className="col-8">
                        <span className="text-dark">
                          Discount: {moveInCost.DiscountDetails.Description}
                        </span>
                      </div>
                      <div className="col-4 text-right">
                        <span className="text-dark">
                          -
                          {formatCurrency(
                            moveInCost.DiscountDetails.DiscountValue
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {taxSum() > 0 && (
                    <div className="row">
                      <div className="col-8">
                        <span className="text-dark">Tax Amount</span>
                      </div>
                      <div className="col-4 text-right">
                        <span className="text-dark">{formatCurrency(taxSum())}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-8 col-md-8 mx-auto mt-3">
              <h4 className="text-dark font-weight-bold">Select Payment method</h4>
              <div className="card mt-3">
                <div className="card-body p-4">
                  <div className="row">
                    <div className="col-2">
                      <img src="/asset/pay.png" alt="Credit Card" style={{ width: "50px" }} />
                    </div>
                    <div className="col-6 align-self-center">
                      <span className="text-dark font-weight-bold h5">Credit Card</span>
                    </div>
                    <div className="col-4">
                      <Link
                        className="btn btn-pink btn-block"
                        to={mylocation.allow_edge_express_in_kiosk ? "/process-payment-card" : "/process-payment-emv"}
                      >
                        Select
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {mylocation.allow_ach && (
                <div className="card mt-3">
                  <div className="card-body p-4">
                    <div className="row">
                      <div className="col-2">
                        <img
                          alt="ACH Payment"
                          src="/asset/Page-1.png"
                          height="45"
                          width="auto"
                          className="mb-3 mb-lg-0"
                        />
                      </div>
                      <div className="col-6 align-self-center">
                        <span className="text-dark font-weight-bold h5">Bank Account</span>
                      </div>
                      <div className="col-4">
                        <Link className="btn btn-pink btn-block" to="/process-payment-ach">
                          Select
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BackButton backPath="/lease-summary" />
    </React.Fragment>
  );
};

const PAGE_ID = "new_tenant_checkout_lease_payment_retry_count";
export default withRetryPaymentCount(LeasePaymentForm, PAGE_ID);
