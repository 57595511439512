import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { reservationObject } from "../../Utils/Common";
import BackButton from "../../shared/BackButton";

class ReservationNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservation: reservationObject(),
    };
  }

  nextPage = () => {};

  render() {
    return (
      <Fragment>
        <div className="wrapper wrapper-flex">
          <div className="container">
            <div className="text-center mb-2">
              <h1 className="display-3 font-weight-bold">Reservation not found</h1>
            </div>
            <div className="row my-5">
              <div className="col-12 mx-auto">
                <div className="card px-5 py-4">
                  <div className="card-body text-center">
                    <h3>
                      Sorry! We can't seem to find that information. Please re-enter your information and try again.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <NavLink className="btn btn-secondary btn-lg btn-block" to="/climate-control">
                  Browse Availability
                </NavLink>
              </div>
              <div className="col-6 text-right">
                <NavLink className="btn btn-pink btn-lg btn-block" to="/find-reservation">
                  Try Again
                </NavLink>
              </div>
            </div>
          </div>
          <BackButton backPath="/find-reservation" />
        </div>
      </Fragment>
    );
  }
}

export default ReservationNotFound;
