import React from "react";
import { Redirect } from "react-router-dom";
import {
  countPaymentRetry,
  MAX_RETRY_PAYMENT_COUNT,
  paymentRetryFailureAlert,
  resetNumberOfRetryPayment,
} from "../../Utils/paygateway";

function withRetryPaymentCount(WrappedComponent, pageId, paymentMethodURL, isExistingTenantFlow = false) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        nRetryPayment: 0,
      };
    }

    componentDidMount = () => {
      const nRetryPayment = countPaymentRetry(pageId || "process_payment_retry", this.props.location.search);
      this.setState({ nRetryPayment });
    };

    render() {
      if (this.state.nRetryPayment >= MAX_RETRY_PAYMENT_COUNT) {
        if (!isExistingTenantFlow) {
          paymentRetryFailureAlert();
        }
        resetNumberOfRetryPayment(pageId || "process_payment_retry");
        if (paymentMethodURL) {
          return <Redirect to={paymentMethodURL} />;
        }
      }
      return <WrappedComponent />;
    }
  };
}

export default withRetryPaymentCount;
