import React from "react";
import { PropTypes } from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import BackButton from "../../../shared/BackButton";
import { getInsuranceType } from "../../../Utils/Common";

const RESOURCE_HOSTS = {
  production: "https://app.spareboxstorage.com",
  staging: "https://staging-app.spareboxstorage.com",
  localhost: "https://staging-app.spareboxstorage.com",
};

function ShieldBrochure(props) {
  const shieldBrochurePdfUrl = sessionStorage.getItem("shieldBrochurePdfUrl");
  const relativePdfURL = `${RESOURCE_HOSTS[process.env.REACT_APP_ENV]}/img/${shieldBrochurePdfUrl}`;
  const isAccessibleMode = sessionStorage.getItem("accessible") === "on";

  const handleNextPage = () => {
    props.history.push("/lease-summary", { prevPageUrl: "/shield-brochure" });
  };

  if (!shieldBrochurePdfUrl)
    return (
      <div className="wrapper wrapper-flex py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <ClipLoader />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="wrapper wrapper-flex py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="text-center">
              <h1 className="font-weight-bold">{`${getInsuranceType()} Brochure`}</h1>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="pdf-modal-content" style={{ maxHeight: isAccessibleMode ? "30vh" : "60vh" }}>
            <object
              title="Lease PDF"
              type="application/pdf"
              data={`${relativePdfURL}#toolbar=0`}
              width="100%"
              height="100%"
            >
              <p>
                {"Your web browser doesn't have a PDF plugin. Instead you can "}
                <a href={relativePdfURL} rel="noopener noreferrer" download>
                  {"click here to download the PDF file."}
                </a>
              </p>
            </object>
          </div>
        </div>
        <div className="row mt-4 d-flex align-items-center justify-content-between">
          <button
            style={{ marginLeft: "auto" }}
            className="btn btn-pink btn-lg letter-spacing-2"
            onClick={handleNextPage}
          >
            {"Next"}
          </button>
        </div>
      </div>
      <BackButton backPath="/insurance" />
    </div>
  );
}

ShieldBrochure.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default ShieldBrochure;
