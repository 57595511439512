import React from "react";
// import axios from 'axios';
// import { setLocationObject } from "../../Utils/Common";

function S0105_S0109() {
  // const [locationLeft, setLocationLeft] = useState({});
  // state = {
  //   location_left: false,
  //   location_right: false,
  // }

  // selectLocation = (location) => {
  //   setLocationObject(location);
  //   this.props.history.push("/home");
  // };

  // const loadLocations = () => {
  //   (() => {
  //   effect
  // }, []);

  // const loadLocations = () => {
  //   const locationCodes = ['157', '160']; // S0004, S0005

  //   locationCodes.forEach((code) => {
  //     axios.get("/api/locations?location_code=" + code + "&role=kiosk").then((response) => {
  //       const location = response.data;
  //       if (!location) return;

  //       // Locations should be arranged in order.
  //       if (location.location_code === "157") {
  //         setLocationLeft()
  //         this.setState({ location_left: location });
  //       }
  //       if (location.location_code === "160")
  //         this.setState({ location_right: location });
  //       }
  //     })
  //   })
  // }

  return (
    <div className="wrapper wrapper-flex py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="text-center">
              <h1 className="font-weight-bold">Please select a unit type:</h1>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-12 col-md-8 mx-auto">
            <a href="/locations?code=157" className="btn btn-black btn-lg letter-spacing-2 btn-block mb-5">
              Traditional Storage
            </a>
            <a href="/locations?code=160" className="btn btn-pink btn-lg letter-spacing-2 btn-block">
              Boat and RV Parking
            </a>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-sm-12 col-md-8 mx-auto">
          <a href="/locations?code=157" className="btn btn-black btn-lg letter-spacing-2 btn-block mb-5">
            Traditional Storage
          </a>
          <a href="/locations?code=160" className="btn btn-pink btn-lg letter-spacing-2 btn-block">
            Parking and RV
          </a>
        </div>
      </div>
    </div>
  );
}

export default S0105_S0109;
