import moment from "moment";
import axios from "axios";
import {
  amountDue,
  autopayBit,
  finalDiscountId,
  getGateCode,
  insuranceId,
  locationCode,
  pendingPaymentObject,
  reservationObject,
  reservedUnitObject,
  sessionObject,
} from "../Utils/Common";
import { logApiFail } from "../Utils/rollbar";

export const processACHPayments = ({ routingNumber, accountNumber, retryCount = 0 }) => {
  const loginObj = sessionObject();
  const paymentObject = pendingPaymentObject();
  const currentDate = moment().format("LL");

  const data = {
    service_name: "ProcessACHPayments",
    xml_body: {
      strLocationCode: locationCode(),
      TenantId: loginObj.id,
      strLeaseNumbers: paymentObject.leaseNumber,
      strCurrentDateTime: currentDate,
      strACHAmount: paymentObject.rent,
      strACHAccountNumber: accountNumber,
      strACHRoutingNumber: routingNumber,
      strACHAccountType: "Checking",
      intPrePaymentPeriod: parseInt(paymentObject.prePayPeriods),
      AutopayACH: autopayBit(),
      AutoAchProcessingDay: "1",
    },
  };

  return axios
    .post("/api/ssm", data)
    .then((response) => {
      return response.data.ProcessPayment;
    })
    .catch((e) => {
      if (retryCount < 3) {
        // Retry after 3 seconds. Retry count limit: 3.
        setTimeout(() => {
          processACHPayments({ routingNumber, accountNumber, retryCount: retryCount + 1 });
        }, 3000);
      } else {
        logApiFail("ProcessACHPayments", data, e);
      }
    });
};

export const processACHMoveIn = ({ routingNumber, accountNumber, retryCount = 0 }) => {
  const reservationDetails = reservationObject();
  const unitData = reservedUnitObject();

  const startDate = moment().format("L");
  const endDate = moment().add(29, "days").format("L");

  const data = {
    service_name: "ProcessACHMoveIn",
    xml_body: {
      strLocationCode: locationCode(),
      TenantId: reservationDetails.tenantId,
      AccessCode: getGateCode(),
      UnitId: unitData.uId,
      InsuranceId: insuranceId(),
      DiscountId: finalDiscountId(),
      StartDate: startDate,
      EndDate: endDate,
      PaymentAmount: parseFloat(amountDue()).toFixed(2),
      ACHAccountNumber: accountNumber,
      ACHRoutingNumber: routingNumber,
      ACHAccountType: "Checking",
      OverrideUnitStatus: "False",
      AutopayACH: autopayBit(),
      AutoAchProcessingDay: "1",
      strDocRefNumber: "",
      docBase64Format: localStorage.getItem("signed_lease_pdf") || "",
    },
  };

  return axios
    .post("/api/ssm", data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      if (retryCount < 3) {
        // Retry after 3 seconds. Retry count limit: 3.
        setTimeout(() => {
          processACHMoveIn({ routingNumber, accountNumber, retryCount: retryCount + 1 });
        }, 3000);
      } else {
        logApiFail("ProcessACHMoveIn", data, e);
      }
    });
};
